import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Button } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import LastPageIcon from '@material-ui/icons/LastPage';
import AutorenewIcon from '@material-ui/icons/Autorenew';

export default function MoveButtons(props) {
  return (
    <Grid item xs={6}>
      <Button
        variant="contained"
        color="primary"
        size="large"
        style={{ maxWidth: props.buttonWidth, minWidth: props.buttonWidth }}
        onClick={props.firstMove}
        // if (props.plyViewed > 0) {
        //   props.plyViewed = 0;
        //   props.setFen(props.fenHistory[props.plyViewed]);
        //   props.setSelectedIndex(props.plyViewed);
        //   props.board.current.setPosition(props.fenHistory[props.plyViewed]);
        // }

        // className={classes.marginButton}
      >
        <FirstPageIcon />
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        style={{ maxWidth: props.buttonWidth, minWidth: props.buttonWidth }}
        onClick={props.previousMove}
        // if (props.plyViewed > 0) {
        //   props.plyViewed--;
        //   props.board.current.setPosition(props.fenHistory[props.plyViewed]);
        //   props.setFen(props.fenHistory[props.plyViewed]);
        //   props.setSelectedIndex(props.plyViewed);
        // }
      >
        <NavigateBeforeIcon />
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        style={{ maxWidth: props.buttonWidth, minWidth: props.buttonWidth }}
        onClick={props.nextMove}
        // if (props.plyViewed < props.fenHistory.length - 1) {
        //   props.plyViewed++;
        //   props.board.current.setPosition(props.fenHistory[props.plyViewed]);
        //   props.setFen(props.fenHistory[props.plyViewed]);
        //   props.setSelectedIndex(props.plyViewed);
        // }
      >
        <NavigateNextIcon />
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        style={{ maxWidth: props.buttonWidth, minWidth: props.buttonWidth }}
        onClick={props.lastMove}
        // if (props.plyViewed < props.fenHistory.length - 1) {
        //   props.plyViewed = props.fenHistory.length - 1;
        //   props.board.current.setPosition(props.fenHistory[props.plyViewed]);
        //   props.setFen(props.fenHistory[props.plyViewed]);
        //   props.setSelectedIndex(props.plyViewed);
        // }
      >
        <LastPageIcon />
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        style={{ maxWidth: props.buttonWidth, minWidth: props.buttonWidth }}
        onClick={props.boardOrientationSwitch}
        // remmed below here

        // props.board.current.setOrientation(
        //   props.board.current.getOrientation() === 'w' ? 'b' : 'w'
        // );
        // props.boardOrientation = props.board.current.getOrientation();
        // // alert(boardOrientation);
        // if (props.switchState.checkedA) {
        //   props.showPieceValues(
        //     props.gameDetails,
        //     props.boardOrientation,
        //     props.chess,
        //     props.fenHistory
        //   );
        // }
      >
        <AutorenewIcon />
      </Button>

      <Tooltip title="Piece Values" aria-label="add" placement="top">
        <Switch
          checked={props.switchState.checkedA}
          onChange={props.handleChangeSwitches}
          name="checkedA"
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </Tooltip>

      {/* </Grid> */}
    </Grid>
  );
}
