import { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import Chess from s'chess.js';

// export default function moveHistory(
export const MoveList = (
  // export default function MoveList(
  // {
  // chess,
  // board,
  // fenHistory,
  // plyViewed,
  // classes,
  // selectedIndex,
  // handleListItemClick,
  // setFen,
  // setSelectedIndex,
  // }
  props
) => {
  // console.log('mychessobject', props.chess.history());
  let moveHistory = props.chess.history();
  // plyViewed = props.currentPlyViewed;

  return (
    <Grid container direction="row">
      <Grid item xs={2}>
        <List component="nav" aria-label="secondary mailbox folders">
          {props.chess.history().map((each, index) => {
            if (index % 2 === 0) {
              return (
                <ListItem
                  key={each.concat('moveNumber').concat(index.toString())}
                  style={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}
                >
                  <ListItemText
                    // style={{ lineHeight: 0, margin: 0 }}
                    // classes={{ primary: props.classes.listItemText }}
                    primary={`${index / 2 + 1}.`}
                  />
                </ListItem>
              );
            } else return null;
          })}
        </List>
      </Grid>

      <Grid item xs={4}>
        <List component="nav" aria-label="secondary mailbox folders">
          {props.chess.history().map((each, index) => {
            if (index % 2 === 0) {
              return (
                <ListItem
                  key={each.concat('white').concat(index.toString())}
                  style={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}
                  button
                  selected={props.selectedIndex === index + 1}
                  onClick={event => {
                    props.board.current.setPosition(
                      props.fenHistory[index + 1]
                    );

                    // props.plyViewed = index + 1;
                    console.log('plyViewed before', props.plyViewed);
                    props.changePlyViewedValue(index + 1);
                    console.log('plyViewed after', props.plyViewed);
                    props.handleListItemClick(event, index + 1);
                    props.setFen(props.fenHistory[index + 1]);
                    props.setSelectedIndex(props.plyViewed);
                  }}
                >
                  <ListItemText
                    // style={{ lineHeight: 0, margin: 0 }}
                    // classes={{ primary: props.classes.listItemText }}
                    primary={moveHistory[index]}
                  />
                </ListItem>
              );
            } else return null;
          })}
        </List>
      </Grid>

      <Grid item xs={4}>
        <List
          component="nav"
          // aria-label="secondary mailbox folders"
        >
          {props.chess.history().map((each, index) => {
            if (index % 2 !== 0) {
              return (
                <ListItem
                  key={each.concat('black').concat(index.toString())}
                  style={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}
                  button
                  selected={props.selectedIndex === index + 1}
                  onClick={event => {
                    props.board.current.setPosition(
                      props.fenHistory[index + 1]
                    );
                    // props.plyViewed = index + 1;
                    props.changePlyViewedValue(index + 1);
                    props.handleListItemClick(event, index + 1);
                    props.setFen(props.fenHistory[index + 1]);
                    props.setSelectedIndex(props.plyViewed);
                  }}
                >
                  <ListItemText
                    // style={{ lineHeight: 0, margin: 0 }}
                    // classes={{ primary: props.classes.listItemText }}
                    // dense={true}
                    // primary={props.chess.history()[index]}
                    primary={moveHistory[index]}
                  />
                </ListItem>
              );
            } else return null;
          })}
        </List>
      </Grid>
    </Grid>
  );
};

export default MoveList;
