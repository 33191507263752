import React from 'react';
import { Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';

const AdvisorTags = props => {
  let heading;

  switch (props.category) {
    case 'WhiteAdvantages':
      heading = 'White Advantages';
      break;
    case 'BlackAdvantages':
      heading = 'Black Advantages';
      break;
    case 'WhiteAdvisory':
      heading = 'White Advisory';
      break;
    case 'BlackAdvisory':
      heading = 'Black Advisory';
      break;
    case 'Neutral':
      heading = 'Neutral';
      break;

    default:
      heading = 'test';
      break;
  }

  const getMyHeader = (heading, advisorTags) => {
    if (advisorTags.length > 0) {
      return (
        <Typography
          variant="h6"
          component="h6"
          style={{
            fontSize: 16,
            paddingTop: heading === 'White Advantages' ? 0 : 10,
          }}
        >
          {heading}
        </Typography>
      );
    } else {
      return '';
    }
  };

  let header = getMyHeader(heading, props.advisorTags);

  let html = props.advisorTags.map(function (name1, index1) {
    return (
      <Typography
        style={{
          lineHeight: '1.5',
          marginLeft: '0px',
          padding: '0',
        }}
      >
        <Link
          href="#"
          onClick={event => {
            props.popupInstruction(name1, props.category);
          }}
          onMouseOver={event => {
            props.highlightSquares(
              name1,
              props.category,
              props.gameDetails,
              props.board
            );
          }}
          onMouseLeave={event =>
            props.board.current.removeMarkers(undefined, undefined)
          }
          variant="body2"
        >
          - {name1}
        </Link>
      </Typography>
    );
  });

  return (
    <Box>
      {header}
      {html}
    </Box>
  );
};

export default AdvisorTags;
