import React, { useState, useEffect, useRef, forwardRef } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Footer from './ui/Footer';
import readStream from '../library/ndjson-stream-reader';
import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import LaunchIcon from '@material-ui/icons/Launch';

import { useHistory } from 'react-router-dom';

import './../styles/games.css';

// import { handleReceiveSelectedGame } from './AnalyseGame';

export const Games = props => {
  // const classes = useStyles();

  const [showCircularProgress, setShowCircularProgress] = useState(false);
  const [gamesList, setGamesList] = useState();

  const [selectedGamesRow, setSelectedGamesRow] = useState(null);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    LaunchIcon: forwardRef((props, ref) => <LaunchIcon {...props} ref={ref} />),
  };

  const columns = [
    { title: 'White', field: 'white' },
    { title: 'Rating', field: 'whiteRating', type: 'numeric' },
    { title: 'Black', field: 'black' },
    { title: 'Rating', field: 'blackRating', type: 'numeric' },
    { title: 'Result', field: 'result', type: 'string' },
  ];

  const [tableData, setTableData] = useState([]);

  const parseTableData = arrCollection => {
    let importedRecords = arrCollection;
    let dataArray = [];
    for (const property in importedRecords) {
      let parsedData = {
        white: '',
        black: '',
        whiteRating: null,
        blackRating: null,
        result: '',
        pgn: '',
      };
      // if (importedRecords[property].id !== undefined) {
      //   parsedData.id = importedRecords[property].id;
      // }
      if (importedRecords[property].players?.white?.user?.name !== undefined) {
        parsedData.white = importedRecords[property].players.white.user.name;
      }
      if (importedRecords[property].players?.black?.user?.name !== undefined) {
        parsedData.black = importedRecords[property].players.black.user.name;
      }
      if (importedRecords[property].players.white?.rating !== undefined) {
        parsedData.whiteRating = importedRecords[property].players.white.rating;
      }
      if (importedRecords[property].players.black?.rating !== undefined) {
        parsedData.blackRating = importedRecords[property].players.black.rating;
      }
      if (importedRecords[property].winner !== undefined) {
        let result = '';
        if (importedRecords[property].winner === 'white') {
          parsedData.result = '1 - 0';
        }
        if (importedRecords[property].winner === 'black') {
          parsedData.result = '0 - 1';
        }
        if (importedRecords[property].winner === 'draw') {
          parsedData.result = 'draw';
        }
        // parsedData.result = importedRecords[property].players.black.rating;
      }
      if (importedRecords[property].pgn !== undefined) {
        parsedData.pgn = importedRecords[property].pgn;
      }
      // console.log(importedRecords[property].moves);
      dataArray.push(parsedData);
    }
    console.log(dataArray);
    setTableData(dataArray);
    props.setImportedGamesTableData(dataArray);
  };

  //Code to open Analyse Games page with correct pgn data
  const history = useHistory();

  const routeChange = props => {
    alert(props.rowData);
    let path = `analyze`;
    history.push(path);
    props.setPassedGamesFlag(1);
    // console.log('game data', props.rowData);
    // props.setSelectedGamesPGN(props.rowData.pgn);
    // props.rowData.pgn;
    // alert('go');

    // handleReceiveSelectedGame();
  };

  return (
    // <ThemeProvider theme={theme}>
    <div style={{ display: 'flex', alignItems: 'baseline' }}>
      <Grid
        container
        //Main page container
        justify="center"
        // style={{ backgroundColor: 'white' }}
        // style={{ padding: '0px' }}
      >
        <Paper
          // className={classes.paper}
          // style={{ maxHeight: 600, overflow: 'auto' }}
          // style={{ padding: '25px' }}
          elevation={0}
        >
          <Box mt={5} mb={5} width={1200}>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={7}>
                <Typography
                  variant="h3"
                  style={{ fontWeight: 600, marginBottom: '16px' }}
                >
                  Games List
                </Typography>
                <MaterialTable
                  columns={columns}
                  data={props.importedGamesTableData}
                  // data={tableData}
                  onRowClick={(evt, selectedGamesRow) => {
                    setSelectedGamesRow(selectedGamesRow.tableData.id);
                    let path = `analyze`;
                    history.push(path);
                    props.setPassedGamesFlag(1);
                    props.setSelectedGamesPGN(selectedGamesRow.pgn);
                  }}
                  title="lichess games"
                  icons={tableIcons}
                  options={{
                    filtering: true,
                    // cellStyle: { padding: '0.1em' },
                    // headerStyle: { padding: '0.1em' },
                    padding: 'FOO-other-than-default',
                    pageSize: 10,
                    rowStyle: rowData => ({
                      backgroundColor:
                        selectedGamesRow === rowData.tableData.id
                          ? '#EEE'
                          : '#FFF',
                    }),
                  }}
                  // actions={[
                  //   {
                  //     icon: () => <LaunchIcon />,
                  //     tooltip: 'Analyze Game',
                  //     onClick: (event, rowData) => {
                  //       // routeChange(rowData);
                  //       // alert(rowData.pgn);
                  //       let path = `analyze`;
                  //       history.push(path);
                  //       props.setPassedGamesFlag(1);
                  //       props.setSelectedGamesPGN(rowData.pgn);
                  //     },
                  //     // alert('You saved ' + rowData.name),
                  //   },
                  // ]}
                  style={{ padding: '25px' }}
                />
                <Grid
                  container
                  direction="row"
                  // justify="space-between"
                  spacing={50}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginTop: '1em', marginRight: '32px' }}
                    // style={{ maxWidth: '108px', minWidth: '108px' }}
                    onClick={() => {
                      setShowCircularProgress(true);
                      let arrCollection = [];
                      // const stream = fetch(
                      //   'https://lichess.org/api/games/user/neio',
                      //   { headers: { Accept: 'application/x-ndjson' } }
                      // );
                      const stream = fetch(
                        'https://lichess.org/api/games/user/JonL12345?tags=true&clocks=false&evals=false&opening=false&max=20&pgnInJson=true',
                        { headers: { Accept: 'application/x-ndjson' } }
                      );
                      const onMessage = obj => {
                        arrCollection.push(obj);
                      };

                      const onComplete = () => {
                        console.log('The stream has completed');
                        setShowCircularProgress(false);
                        console.log('arrCollection', arrCollection);
                        parseTableData(arrCollection);
                      };

                      stream.then(readStream(onMessage)).then(onComplete);
                    }}
                  >
                    Import Games
                  </Button>
                  {showCircularProgress === true ? (
                    <CircularProgress style={{ marginTop: '1em' }} />
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Footer />
      </Grid>
    </div>
    // </ThemeProvider>
  );
};

export default Games;
