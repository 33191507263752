import React from 'react';
import { useEffect } from 'react';
import { Typography } from '@material-ui/core';
// import { Box } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {
  Chessboard,
  // INPUT_EVENT_TYPE,
  // MOVE_INPUT_MODE,
  // COLOR,
} from 'cm-chessboard';

export const IsolatedPawnsWhite = props => {
  // {props.tagText}
  // let colour = props.tagText.includes('dark') ? 'dark' : 'light';

  useEffect(() => {
    new Chessboard(document.getElementById('board3'), {
      position: props.fen,
      // 'rn2k1r1/ppp1pp1p/3p2p1/5bn1/P7/2N2B2/1PPPPP2/2BNK1RR w Gkq - 4 11',
      // orientation: COLOR.black,
    });
  }, []);

  return (
    <>
      <DialogTitle id="helper-dialog-title">
        <Typography variant="h5">
          Black has Isolated Pawns at {props.tagText.substring(15)}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">White's Strategy</Typography>
        <Typography paragraph="true">
          Put pressure on the isolated pawns to exploit their weakness. Use the
          square in front the pawn as an outpost.
        </Typography>
        <Typography variant="h6">Black's Strategy</Typography>
        <Typography paragraph="true">
          Try to exchange off your isolated pawn when possible. Put your Rooks
          or Queen on the open or semi-open file adjacent to the isolated pawn.
        </Typography>
        <Typography variant="h6">Notes</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography paragraph="true">
              Isolated pawns are usually considered a weakness but can also give
              dynamic advantage by helping give open lines for your pieces,
              without being impeaded by your pawns. They tend to become even
              more vulnerable in the endgame.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Paper width="5%" id="board3"></Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export const IsolatedPawnsBlack = props => {
  // {props.tagText}
  // let colour = props.tagText.includes('dark') ? 'dark' : 'light';

  useEffect(() => {
    new Chessboard(document.getElementById('board3'), {
      position: props.fen,
      // 'rn2k1r1/ppp1pp1p/3p2p1/5bn1/P7/2N2B2/1PPPPP2/2BNK1RR w Gkq - 4 11',
      // orientation: COLOR.black,
    });
  }, []);

  return (
    <>
      <DialogTitle id="helper-dialog-title">
        <Typography variant="h5">
          White has Isolated Pawns at {props.tagText.substring(15)}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">White's Strategy</Typography>
        <Typography paragraph="true">
          Try to exchange off your isolated pawn when possible. Put your Rooks
          or Queen on the open or semi-open file adjacent to the isolated pawn.
        </Typography>
        <Typography variant="h6">Black's Strategy</Typography>
        <Typography paragraph="true">
          Put pressure on the isolated pawns to exploit their weakness. Use the
          square in front the pawn as an outpost.
        </Typography>
        <Typography variant="h6">Notes</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography paragraph="true">
              Isolated pawns are usually considered a weakness but can also give
              dynamic advantage by helping give open lines for your pieces,
              without being impeaded by your pawns. They tend to become even
              more vulnerable in the endgame.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Paper width="5%" id="board3"></Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};
