import React, { useState, useEffect, useRef } from 'react';
// import Chess from 'chess.js';
import Header from './components/ui/Header';
import HeaderHome from './components/ui/HeaderHome';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter, Route, Switch as SwitchBR } from 'react-router-dom';
import theme from './components/ui/Theme';
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
// import AdvisorTags from './components/AdvisorTags';
import Home from './components/Home';
import Features from './components/Features';
import Pricing from './components/Pricing';
import About from './components/About';
import Dashboard from './components/Dashboard';
import AnalyseGame from './components/AnalyseGame';
import Games from './components/Games';
import Help from './components/Help';
// import { pgnExpanded, addPgnToArray } from './library/utilities';
// import {
//   showPieceValues,
//   removePieceValues,
//   calculatePieceValues,
// } from './library/showPieceValues';

// import {
//   Chessboard,
//   INPUT_EVENT_TYPE,
//   // MOVE_INPUT_MODE,
//   SQUARE_SELECT_TYPE,
//   MARKER_TYPE,
//   COLOR,
// } from 'cm-chessboard';

import './styles/cm-chessboard.css';
import { gameAnalyser } from './library/gameAnalyser';

function App() {
  const [passedGamesFlag, setPassedGamesFlag] = useState(0);
  const [selectedGamesPGN, setSelectedGamesPGN] = useState('');
  const [importedGamesTableData, setImportedGamesTableData] = useState([]);
  const [currentPlyViewed, setCurrentPlyViewed] = useState();

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          {/* <Header /> */}
          <SwitchBR>
            {/* <Route exact path="/" component={() => <div>Home</div>} /> */}
            <Route exact path="/">
              <HeaderHome />
              <Home />
            </Route>
            <Route exact path="/features">
              <HeaderHome />
              <Features />
            </Route>
            <Route exact path="/pricing">
              <HeaderHome />
              <Pricing />
            </Route>
            <Route exact path="/about">
              <HeaderHome />
              <About />
            </Route>
            />
            <Route exact path="/dashboard">
              <Header />
              <Dashboard />
            </Route>
            />
            <Route exact path="/analyze">
              <Header />
              <AnalyseGame
                passedGamesFlag={passedGamesFlag}
                setPassedGamesFlag={setPassedGamesFlag}
                selectedGamesPGN={selectedGamesPGN}
                setCurrentPlyViewed={setCurrentPlyViewed}
                currentPlyViewed={currentPlyViewed}
              />
            </Route>
            <Route exact path="/games">
              <Header />
              <Games
                setPassedGamesFlag={setPassedGamesFlag}
                setSelectedGamesPGN={setSelectedGamesPGN}
                importedGamesTableData={importedGamesTableData}
                setImportedGamesTableData={setImportedGamesTableData}
                setCurrentPlyViewed={setCurrentPlyViewed}
                currentPlyViewed={currentPlyViewed}
              />
            </Route>
            <Route exact path="/help">
              <Header />
              <Help />
            </Route>
          </SwitchBR>
        </BrowserRouter>
      </ThemeProvider>

      {/* <AnalyseGame /> */}
    </>
  );
}

export default App;
// export default React.memo(App);
