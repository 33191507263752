import React from 'react';
import { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import {
  Chessboard,
  // INPUT_EVENT_TYPE,
  // MOVE_INPUT_MODE,
  // COLOR,
} from 'cm-chessboard';

export const WeakenedPawnsByKingWhite = props => {
  // {props.tagText}

  useEffect(() => {
    new Chessboard(document.getElementById('board3'), {
      position: props.fen,
    });
  }, []);

  return (
    <>
      <DialogTitle id="helper-dialog-title">
        <Typography variant="h5">Weakened Pawns by White King</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">White's Strategy</Typography>
        <Typography paragraph="true">
          Keep alert for potential sacrifices or pawn levers by Black. Consider
          fianchettoing your Bishop if you have advanced your g pawn in front of
          your King.
        </Typography>
        <Typography variant="h6">Black's Strategy</Typography>
        <Typography paragraph="true">
          Exploit the weakness created by the pawn advance. Examples: 1. White
          plays h3: a) This provides a hook to open up the position by advancing
          Black's g pawn; b) Look for tactical shots such as taking the h3 pawn
          with a Knight or Bishop. 2. White plays g3, but no Bishop on g2:
          Consider occupying the weakened f3 and h3 squares.
        </Typography>
        <Typography variant="h6">Notes</Typography>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography paragraph="true">
              Any pawn advance in front of your castled King can weaken its
              position. The exception to this rule is the fiancettoed setup.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Paper width="5%" id="board3"></Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export const WeakenedPawnsByKingBlack = props => {
  // {props.tagText}

  useEffect(() => {
    new Chessboard(document.getElementById('board3'), {
      position: props.fen,
    });
  }, []);

  return (
    <>
      <DialogTitle id="helper-dialog-title">
        <Typography variant="h5">Weakened Pawns by Black King</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">White's Strategy</Typography>
        <Typography paragraph="true">
          Exploit the weakness created by the pawn advance. Examples: 1. Black
          plays h6: a) This provides a hook to open up the position by advancing
          White's g pawn; b) Look for tactical shots such as taking the h6 pawn
          with a Knight or Bishop. 2. Black plays g6, but no Bishop on g7:
          Consider occupying the weakened f6 and h6 squares.
        </Typography>
        <Typography variant="h6">Black's Strategy</Typography>
        <Typography paragraph="true">
          Keep alert for potential sacrifices or pawn levers by White. Consider
          fianchettoing your Bishop if you have advanced your g pawn in front of
          your King.
        </Typography>
        <Typography variant="h6">Notes</Typography>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography paragraph="true">
              Any pawn advance in front of your castled King can weaken its
              position. The exception to this rule is the fiancettoed setup.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Paper width="5%" id="board3"></Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};
