import React from 'react';
import { useEffect } from 'react';
import { Typography } from '@material-ui/core';
// import { Box } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {
  Chessboard,
  // INPUT_EVENT_TYPE,
  // MOVE_INPUT_MODE,
  // COLOR,
} from 'cm-chessboard';

// import './styles/cm-chessboard.css';

export const UnopposedBishopWhite = props => {
  // {props.tagText}
  let colour = props.tagText.includes('dark') ? 'dark' : 'light';

  useEffect(() => {
    new Chessboard(document.getElementById('board3'), {
      position: props.fen,
      // 'rn2k1r1/ppp1pp1p/3p2p1/5bn1/P7/2N2B2/1PPPPP2/2BNK1RR w Gkq - 4 11',
      // orientation: COLOR.black,
    });
  }, []);

  return (
    <>
      <DialogTitle id="helper-dialog-title">
        <Typography variant="h5">
          White's Unopposed {colour}-squared Bishop
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">White's Strategy</Typography>
        <Typography paragraph="true">
          Take advantage of White's weak {colour} squares by using them for
          outposts, attacking and infiltrating.
        </Typography>
        <Typography variant="h6">Black's Strategy</Typography>
        <Typography paragraph="true">
          During the middlegame, try to defend the {colour} squares by using
          your pawns and pieces. Place your pawns on the {colour} squares, which
          helps strengthen these squares.
        </Typography>
        <Typography variant="h6">Notes</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography paragraph="true">
              While many players understand that the two Bishops are an
              advantage, they often forget that the unopposed Bishop can become
              the most powerful minor piece. Remember its importance and how it
              can dominate the squares it attacks, since it doesn't have a
              counterpart.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Paper width="5%" id="board3"></Paper>
          </Grid>
        </Grid>
        {/* <Typography paragraph="true">[Diagram]</Typography> */}
        {/* <Box width="50%">
          
        </Box> */}
      </DialogContent>
    </>
  );
};

export const UnopposedBishopBlack = props => {
  // {props.tagText}
  let colour = props.tagText.includes('dark') ? 'dark' : 'light';

  useEffect(() => {
    new Chessboard(document.getElementById('board3'), {
      position: props.fen,
      // 'rn2k1r1/ppp1pp1p/3p2p1/5bn1/P7/2N2B2/1PPPPP2/2BNK1RR w Gkq - 4 11',
      // orientation: COLOR.black,
    });
  }, []);

  return (
    <>
      <DialogTitle id="helper-dialog-title">
        <Typography variant="h5">
          Black's Unopposed {colour}-squared Bishop
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">White's Strategy</Typography>
        <Typography paragraph="true">
          During the middlegame, try to defend the {colour} squares by using
          your pawns and pieces. Place your pawns on the {colour} squares, which
          helps strengthen these squares.
        </Typography>
        <Typography variant="h6">Black's Strategy</Typography>
        <Typography paragraph="true">
          Take advantage of White's weak {colour} squares by using them for
          outposts, attacking and infiltrating.
        </Typography>
        <Typography variant="h6">Notes</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography paragraph="true">
              While many players understand that the two Bishops are an
              advantage, they often forget that the unopposed Bishop can become
              the most powerful minor piece. Remember its importance and how it
              can dominate the squares it attacks, since it doesn't have a
              counterpart.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Paper width="5%" id="board3"></Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};
