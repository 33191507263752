import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Footer from './ui/Footer';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Link from '@material-ui/core/Link';
import StarIcon from '@material-ui/icons/StarBorder';

import boardExample from '../assets/sampleposition.png';

// const useStyles = makeStyles(theme => ({
// toolbarMargin: { ...theme.mixins.toolbar, marginBottom: '0em' },
// }));

const useStyles = makeStyles(theme => ({
  toolbarMargin: { ...theme.mixins.toolbar, marginBottom: '0em' },

  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: [
      'Max of 30 games',
      '7 advisory tags',
      'No piece value scores',
      'Email support',
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Pro',
    subheader: 'Most popular',
    price: '4.99',
    description: [
      'Max of 500 games',
      '20 advisory tags',
      'Piece value scores',
      'Priority email support',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    title: 'Advanced',
    price: '7.99',
    description: [
      'Max of 20,000 games',
      'All advisory tags',
      'Piece value scores',
      'Priority email support',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
];
const footers = [
  {
    title: 'Company',
    description: ['Team', 'History', 'Contact us', 'Locations'],
  },
  {
    title: 'Features',
    description: [
      'Cool stuff',
      'Random feature',
      'Team feature',
      'Developer stuff',
      'Another one',
    ],
  },
  {
    title: 'Resources',
    description: [
      'Resource',
      'Resource name',
      'Another resource',
      'Final resource',
    ],
  },
  {
    title: 'Legal',
    description: ['Privacy policy', 'Terms of use'],
  },
];

export const Pricing = () => {
  const classes = useStyles();
  return (
    // <ThemeProvider theme={theme}>
    <div style={{ display: 'flex', alignItems: 'baseline' }}>
      <Box mt={10} mb={5} width={1200}>
        <Container maxWidth="md" component="main">
          <Grid container spacing={5} alignItems="flex-end">
            {tiers.map(tier => (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={tier.title === 'Enterprise' ? 12 : 6}
                md={4}
              >
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    action={tier.title === 'Pro' ? <StarIcon /> : null}
                    className={classes.cardHeader}
                  />
                  <CardContent>
                    <div className={classes.cardPricing}>
                      <Typography
                        component="h2"
                        variant="h3"
                        color="textPrimary"
                      >
                        ${tier.price}
                      </Typography>
                      <Typography variant="h6" color="textSecondary">
                        /mo
                      </Typography>
                    </div>
                    <ul>
                      {tier.description.map(line => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      variant={tier.buttonVariant}
                      color="primary"
                    >
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      {/* </Box> */}
      {/* Footer */}
      {/* <Container maxWidth="md" component="footer" className={classes.footer}>
        <Grid container spacing={4} justifyContent="space-evenly">
          {footers.map(footer => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map(item => (
                  <li key={item}>
                    <Link href="#" variant="subtitle1" color="textSecondary">
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Box mt={5}>{ <Copyright /> </Box>
      </Container> */}

      {/* <Grid
        container
        //Main page container
        justify="center"
        style={{ backgroundColor: 'white' }}
        // style={{ padding: '0px' }}
      >
        <Paper
          // className={classes.paper}
          // style={{ maxHeight: 600, overflow: 'auto' }}
          // style={{ padding: '25px' }}
          elevation={0}
        >
          <Box mt={5} mb={5} width={1200}>
            <Grid
              container
              justify="center"
              spacing={2}
              style={{ padding: '25px' }}
            >
              <Grid item xs={7}>
                <Typography variant="h3" style={{ fontWeight: 600 }}>
                  Pricing
                </Typography>

                <Typography
                  variant="h5"
                  style={{ marginTop: '1em', color: 'grey' }}
                >
                  Discover the critical elements of every position, and what
                  chess wisdom advises you to do. Let our AI powered advisor
                  guide your thinking.
                </Typography>

                <Typography variant="body1" style={{ marginTop: '2em' }}>
                  Improving your chess playing strength is not easy. I feel your
                  pain. We all know chess is complex. There are so many aspects
                  to the game. You have tactics, strategy,
                  openings/middlegame/endgame, time management, thinking
                  processes and much much more.
                </Typography>
                <Typography variant="body1" style={{ marginTop: '2em' }}>
                  Improving your chess playing strength is not easy. I feel your
                  pain. We all know chess is complex. There are so many aspects
                  to the game. You have tactics, strategy,
                  openings/middlegame/endgame, time management, thinking
                  processes and much much more.
                </Typography>
                <Typography variant="body1" style={{ marginTop: '2em' }}>
                  Improving your chess playing strength is not easy. I feel your
                  pain. We all know chess is complex. There are so many aspects
                  to the game. You have tactics, strategy,
                  openings/middlegame/endgame, time management, thinking
                  processes and much much more.
                </Typography>
                <Typography variant="body1" style={{ marginTop: '2em' }}>
                  Improving your chess playing strength is not easy. I feel your
                  pain. We all know chess is complex. There are so many aspects
                  to the game. You have tactics, strategy,
                  openings/middlegame/endgame, time management, thinking
                  processes and much much more.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Footer />
      </Grid> */}
    </div>
    // </ThemeProvider>
  );
};

export default Pricing;
