import {
  whiteKnightOutpostPatterns,
  blackKnightOutpostPatterns,
  openingPawnStructurePatterns,
  kingSafetyPawnStructurePatterns,
  miscPawnStructurePatterns,
  weakSquaresPatternsWhite,
  weakSquaresPatternsBlack,
  whitePawnProphylaxisPatterns,
  blackPawnProphylaxisPatterns,
} from './patternArrays';

import {
  convertColumnsToLetters,
  checkPattern,
  columnStringsArray,
  pgnExpanded,
  addPgnToArray,
  reverseString,
} from './utilities';

import {
  checkOpeningPawnStructurePatterns,
  checkKingSafetyPawnStructurePatterns,
  checkMiscPawnStructurePatterns,
  checkKnightOutpostPatterns,
  checkFilesAndPawns,
  checkTwoBishops,
  checkCentralPawnMajority,
  checkDevelopment,
  checkUncastledKing,
  checkWeakSquaresBlack,
  checkWeakSquaresWhite,
  checkQueensideMajority,
  checkCrampedPosition,
  checkWorsedPlacedPiece,
  checkDominateTheKnight,
  checkPawnProphylaxis,
  checkKnightInvader,
  checkConnectedRooks,
  checkLoosePiece,
} from './patternEngine';

import {
  showPieceValues,
  removePieceValues,
  calculatePieceValues,
} from './showPieceValues';

import Chess from 'chess.js';

export function gameAnalyser(
  gameDetails,
  fen,
  plyViewed,
  boardOrientation,
  chess,
  fenHistory
) {
  gameDetails = checkOpeningPawnStructurePatterns(
    openingPawnStructurePatterns,
    checkPattern,
    gameDetails
  );

  gameDetails = checkKingSafetyPawnStructurePatterns(
    kingSafetyPawnStructurePatterns,
    checkPattern,
    gameDetails
  );

  gameDetails = checkMiscPawnStructurePatterns(
    miscPawnStructurePatterns,
    checkPattern,
    gameDetails
  );

  gameDetails = checkKnightOutpostPatterns(
    whiteKnightOutpostPatterns,
    'White',
    checkPattern,
    gameDetails
  );

  gameDetails = checkKnightOutpostPatterns(
    blackKnightOutpostPatterns,
    'Black',
    checkPattern,
    gameDetails
  );

  gameDetails = checkDevelopment(gameDetails);

  gameDetails = checkUncastledKing(gameDetails, fen, plyViewed);

  gameDetails = checkFilesAndPawns(
    columnStringsArray,
    convertColumnsToLetters,
    reverseString,
    gameDetails
  );

  gameDetails = checkTwoBishops(gameDetails);

  gameDetails = checkWeakSquaresBlack(
    weakSquaresPatternsBlack,
    checkPattern,
    gameDetails
  );

  gameDetails = checkWeakSquaresWhite(
    weakSquaresPatternsWhite,
    checkPattern,
    gameDetails
  );

  gameDetails = checkCentralPawnMajority(gameDetails);

  gameDetails = checkQueensideMajority(gameDetails);

  gameDetails = checkCrampedPosition(gameDetails, plyViewed);

  gameDetails = calculatePieceValues(
    gameDetails,
    boardOrientation,
    chess,
    fenHistory
  );

  // console.log('gameanalyser', gameDetails.currentPositionPieceValues);
  // console.log('gameanalyserGameDetails', gameDetails);
  // alert(gameDetails);

  gameDetails = checkWorsedPlacedPiece(
    gameDetails,
    plyViewed,
    boardOrientation
  );

  gameDetails = checkDominateTheKnight(
    gameDetails,
    plyViewed,
    boardOrientation
  );

  gameDetails = checkPawnProphylaxis(
    whitePawnProphylaxisPatterns,
    blackPawnProphylaxisPatterns,
    checkPattern,
    gameDetails
  );

  gameDetails = checkKnightInvader(gameDetails);

  gameDetails = checkConnectedRooks(gameDetails);

  gameDetails = checkLoosePiece(gameDetails, chess, Chess, fen);

  return gameDetails;
}
