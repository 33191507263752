import { checkPieceInColumn } from './utilities';
import { checkPieceInColumnArray } from './utilities';
import { checkDevelopmentByColour } from './utilities';
import { convertColumnsToLetters } from './utilities';
import { retrievePiece, upperCase } from './utilities';
// import { columnStringsArray } from './utilities';
import {
  columnStringsArray,
  pgnExpanded,
  countCharacterInString,
  roundNumber,
  checkPieceInRowArray,
} from './utilities';

// import { weakSquaresWhite } from './patternArrays';
import { allSquaresBlack } from './patternArrays';
import { allSquaresWhite } from './patternArrays';

export const checkOpeningPawnStructurePatterns = (
  openingPawnStructurePatterns,
  checkPattern,
  gameDetails
) => {
  let pawnStructureTags = [];

  openingPawnStructurePatterns.forEach(patternElement => {
    if (checkPattern(patternElement[1], gameDetails.currentPosition) === true) {
      pawnStructureTags.push(patternElement[0]);
      gameDetails.neutralTags.push(patternElement[0]);
      gameDetails.totalTags.push(patternElement[0]);
    }
  });

  return gameDetails;
};

export const checkKingSafetyPawnStructurePatterns = (
  kingSafetyPawnStructurePatterns,
  checkPattern,
  gameDetails
) => {
  let pawnStructureTags = [];

  kingSafetyPawnStructurePatterns.forEach(patternElement => {
    if (
      checkPattern(patternElement[1], gameDetails.currentPosition) === true &&
      patternElement[2] === 'white advantage'
    ) {
      pawnStructureTags.push(patternElement[0]);
      gameDetails.whiteAdvantageTags.push(patternElement[0]);
      gameDetails.totalTags.push(patternElement[0]);
    } else if (
      checkPattern(patternElement[1], gameDetails.currentPosition) === true &&
      patternElement[2] === 'black advantage'
    ) {
      pawnStructureTags.push(patternElement[0]);
      gameDetails.blackAdvantageTags.push(patternElement[0]);
      gameDetails.totalTags.push(patternElement[0]);
    }
  });

  return gameDetails;
};

export const checkMiscPawnStructurePatterns = (
  miscPawnStructurePatterns,
  checkPattern,
  gameDetails
) => {
  let pawnStructureTags = [];

  miscPawnStructurePatterns.forEach(patternElement => {
    if (
      checkPattern(patternElement[1], gameDetails.currentPosition) === true &&
      patternElement[2] === 'white advantage'
    ) {
      pawnStructureTags.push(patternElement[0]);
      gameDetails.whiteAdvantageTags.push(patternElement[0]);
      gameDetails.totalTags.push(patternElement[0]);
    } else if (
      checkPattern(patternElement[1], gameDetails.currentPosition) === true &&
      patternElement[2] === 'black advantage'
    ) {
      pawnStructureTags.push(patternElement[0]);
      gameDetails.blackAdvantageTags.push(patternElement[0]);
      gameDetails.totalTags.push(patternElement[0]);
    }
  });

  return gameDetails;
};

export const checkKnightOutpostPatterns = (
  knightOutposts,
  colour,
  checkPattern,
  gameDetails
) => {
  let knightOutpostTags = [];

  knightOutposts.forEach(patternElement => {
    if (
      checkPattern(patternElement[1], gameDetails.currentPosition) === true &&
      knightOutpostTags.includes(patternElement[0]) === false
    ) {
      knightOutpostTags.push(patternElement[0]);
    }
  });

  if (knightOutpostTags.length === 1 && colour === 'White') {
    gameDetails.whiteAdvantageTags.push(
      `Protected Outpost: ${knightOutpostTags.join(' ')}`
    );
    gameDetails.whiteKnightOutpostSquares = knightOutpostTags.slice();
    gameDetails.totalTags.push(
      `Protected Outpost: ${knightOutpostTags.join(' ')}`
    );
    return gameDetails;
  } else if (knightOutpostTags.length > 1 && colour === 'White') {
    gameDetails.whiteAdvantageTags.push(
      `Protected Outposts: ${knightOutpostTags.join(' / ')}`
    );
    gameDetails.whiteKnightOutpostSquares = knightOutpostTags.slice();
    gameDetails.totalTags.push(
      `Protected Outposts: ${knightOutpostTags.join(' / ')}`
    );

    return gameDetails;
  } else if (knightOutpostTags.length === 1 && colour === 'Black') {
    gameDetails.blackAdvantageTags.push(
      `Protected Outpost: ${knightOutpostTags.join(' ')}`
    );
    gameDetails.blackKnightOutpostSquares = knightOutpostTags.slice();
    gameDetails.totalTags.push(
      `Protected Outpost: ${knightOutpostTags.join(' ')}`
    );
    return gameDetails;
  } else if (knightOutpostTags.length > 1 && colour === 'Black') {
    gameDetails.blackAdvantageTags.push(
      `Protected Outposts: ${knightOutpostTags.join(' / ')}`
    );
    gameDetails.blackKnightOutpostSquares = knightOutpostTags.slice();
    gameDetails.totalTags.push(
      `Protected Outposts: ${knightOutpostTags.join(' / ')}`
    );

    return gameDetails;
  } else {
    return gameDetails;
  }
};

export const checkFilesAndPawns = (
  columnStringsArray,
  convertColumnsToLetters,
  reverseString,
  gameDetails
) => {
  // let tmpTags = [];
  let finalColumnStringsArray = [];

  finalColumnStringsArray = columnStringsArray(gameDetails.currentPosition);

  // loop through string columns
  finalColumnStringsArray.forEach((column, index) => {
    // Check open files and semi-open files

    if (column === '') {
    } else if (
      (column.match(/P/g) || []).length === 0 &&
      (column.match(/p/g) || []).length === 0
    ) {
      gameDetails.openFiles.push(convertColumnsToLetters(index));
    } else if (
      (column.match(/P/g) || []).length === 0 &&
      (column.match(/p/g) || []).length > 0
    ) {
      gameDetails.whiteSemiOpenFiles.push(convertColumnsToLetters(index));
    } else if (
      (column.match(/P/g) || []).length > 0 &&
      (column.match(/p/g) || []).length === 0
    ) {
      gameDetails.blackSemiOpenFiles.push(convertColumnsToLetters(index));
    }

    // Check doubled pawns
    if ((column.match(/P/g) || []).length === 2) {
      // Loop through column string and create array
      column.split('').forEach((element, i) => {
        if (element === 'P') {
          gameDetails.whiteDoubledPawns.push(
            convertColumnsToLetters(index) + (i + 1)
          );
        }
      });
    }

    if ((column.match(/p/g) || []).length === 2) {
      // Loop through column string and create array
      column.split('').forEach((element, i) => {
        if (element === 'p') {
          gameDetails.blackDoubledPawns.push(
            convertColumnsToLetters(index) + (i + 1)
          );
        }
      });
    }

    // Check isolated pawns

    // White isolated pawns
    //a file
    if (
      ((index === 0 && column.match(/P/g)) || []).length > 0 &&
      (finalColumnStringsArray[index + 1].match(/P/g) || []).length === 0
    ) {
      gameDetails.whiteIsolatedPawns.push(
        convertColumnsToLetters(index) + (column.lastIndexOf('P') + 1)
      );
    }
    // b to g files
    if (
      ((index < 7 && index > 0 && column.match(/P/g)) || []).length > 0 &&
      (finalColumnStringsArray[index + 1].match(/P/g) || []).length === 0 &&
      (finalColumnStringsArray[index - 1].match(/P/g) || []).length === 0
    ) {
      gameDetails.whiteIsolatedPawns.push(
        convertColumnsToLetters(index) + (column.lastIndexOf('P') + 1)
      );
    }
    //h file
    if (
      ((index === 7 && column.match(/P/g)) || []).length > 0 &&
      (finalColumnStringsArray[index - 1].match(/P/g) || []).length === 0
    ) {
      gameDetails.whiteIsolatedPawns.push(
        convertColumnsToLetters(index) + (column.lastIndexOf('P') + 1)
      );
    }

    // Black isolated pawns
    //a file
    if (
      ((index === 0 && column.match(/p/g)) || []).length > 0 &&
      (finalColumnStringsArray[index + 1].match(/p/g) || []).length === 0
    ) {
      gameDetails.blackIsolatedPawns.push(
        convertColumnsToLetters(index) +
          (8 - reverseString(column).lastIndexOf('p'))
      );
    }
    // b to g files
    if (
      ((index < 7 && index > 0 && column.match(/p/g)) || []).length > 0 &&
      (finalColumnStringsArray[index + 1].match(/p/g) || []).length === 0 &&
      (finalColumnStringsArray[index - 1].match(/p/g) || []).length === 0
    ) {
      gameDetails.blackIsolatedPawns.push(
        convertColumnsToLetters(index) +
          (8 - reverseString(column).lastIndexOf('p'))
      );
    }
    //h file
    if (
      ((index === 7 && column.match(/p/g)) || []).length > 0 &&
      (finalColumnStringsArray[index - 1].match(/p/g) || []).length === 0
    ) {
      gameDetails.blackIsolatedPawns.push(
        convertColumnsToLetters(index) +
          (8 - reverseString(column).lastIndexOf('p'))
      );
    }

    // White passed pawns

    //a file
    if (
      index === 0 &&
      (column.match(/P/g) || []).length > 0 &&
      column.lastIndexOf('P') >
        finalColumnStringsArray[index].lastIndexOf('p') &&
      column.lastIndexOf('P') >=
        finalColumnStringsArray[index + 1].lastIndexOf('p')
    ) {
      gameDetails.whitePassedPawns.push(
        convertColumnsToLetters(index) + (column.lastIndexOf('P') + 1)
      );
    }

    // b to g files
    //TODO:
    if (
      index > 0 &&
      index < 7 &&
      (column.match(/P/g) || []).length > 0 &&
      column.lastIndexOf('P') >
        finalColumnStringsArray[index].lastIndexOf('p') &&
      column.lastIndexOf('P') >=
        finalColumnStringsArray[index + 1].lastIndexOf('p') &&
      column.lastIndexOf('P') >=
        finalColumnStringsArray[index - 1].lastIndexOf('p')
    ) {
      gameDetails.whitePassedPawns.push(
        convertColumnsToLetters(index) + (column.lastIndexOf('P') + 1)
      );
    }

    //h file
    if (
      index === 7 &&
      (column.match(/P/g) || []).length > 0 &&
      column.lastIndexOf('P') >
        finalColumnStringsArray[index].lastIndexOf('p') &&
      column.lastIndexOf('P') >=
        finalColumnStringsArray[index - 1].lastIndexOf('p')
    ) {
      gameDetails.whitePassedPawns.push(
        convertColumnsToLetters(index) + (column.lastIndexOf('P') + 1)
      );
    }
    // *************************************************
    // Black passed pawns

    //a file

    if (
      index === 0 &&
      (column.match(/p/g) || []).length > 0 &&
      reverseString(column).lastIndexOf('p') >
        reverseString(finalColumnStringsArray[index]).lastIndexOf('P') &&
      reverseString(column).lastIndexOf('p') >=
        reverseString(finalColumnStringsArray[index + 1]).lastIndexOf('P')
    ) {
      gameDetails.blackPassedPawns.push(
        convertColumnsToLetters(index) +
          (8 - reverseString(column).lastIndexOf('p'))
      );
    }

    // b to g files

    if (
      index > 0 &&
      index < 7 &&
      (column.match(/p/g) || []).length > 0 &&
      reverseString(column).lastIndexOf('p') >
        reverseString(finalColumnStringsArray[index]).lastIndexOf('P') &&
      reverseString(column).lastIndexOf('p') >=
        reverseString(finalColumnStringsArray[index + 1]).lastIndexOf('P') &&
      reverseString(column).lastIndexOf('p') >=
        reverseString(finalColumnStringsArray[index - 1]).lastIndexOf('P')
    ) {
      gameDetails.blackPassedPawns.push(
        convertColumnsToLetters(index) +
          (8 - reverseString(column).lastIndexOf('p'))
      );
    }

    // //h file

    if (
      index === 7 &&
      (column.match(/p/g) || []).length > 0 &&
      reverseString(column).lastIndexOf('p') >
        reverseString(finalColumnStringsArray[index]).lastIndexOf('P') &&
      reverseString(column).lastIndexOf('p') >=
        reverseString(finalColumnStringsArray[index - 1]).lastIndexOf('P')
    ) {
      gameDetails.blackPassedPawns.push(
        convertColumnsToLetters(index) +
          (8 - reverseString(column).lastIndexOf('p'))
      );
    }
  });

  if (gameDetails.openFiles.length !== 0) {
    gameDetails.neutralTags.push(
      `Open files: ${gameDetails.openFiles.join(' / ')}`
    );
    gameDetails.totalTags.push(
      `Open files: ${gameDetails.openFiles.join(' / ')}`
    );
    gameDetails.totalTags.flat();
  }

  if (gameDetails.whiteSemiOpenFiles.length !== 0) {
    gameDetails.whiteAdvantageTags.push(
      `Semi-open files: ${gameDetails.whiteSemiOpenFiles.join(' / ')}`
    );
    gameDetails.totalTags.push(
      `Semi-open files: ${gameDetails.whiteSemiOpenFiles.join(' / ')}`
    );
    gameDetails.totalTags.flat();
  }

  if (gameDetails.blackSemiOpenFiles.length !== 0) {
    gameDetails.blackAdvantageTags.push(
      `Semi-open files: ${gameDetails.blackSemiOpenFiles.join(' / ')}`
    );
    gameDetails.totalTags.push(
      `Semi-open files: ${gameDetails.blackSemiOpenFiles.join(' / ')}`
    );
    gameDetails.totalTags.flat();
  }

  if (gameDetails.whiteDoubledPawns.length !== 0) {
    gameDetails.blackAdvantageTags.push(
      `Doubled pawns: ${gameDetails.whiteDoubledPawns.join(' / ')}`
    );
    gameDetails.totalTags.push(
      `Doubled pawns: ${gameDetails.whiteDoubledPawns.join(' / ')}`
    );
    gameDetails.totalTags.flat();
  }
  if (gameDetails.blackDoubledPawns.length !== 0) {
    gameDetails.whiteAdvantageTags.push(
      `Doubled pawns: ${gameDetails.blackDoubledPawns.join(' / ')}`
    );
    gameDetails.totalTags.push(
      `Doubled pawns: ${gameDetails.blackDoubledPawns.join(' / ')}`
    );
    gameDetails.totalTags.flat();
  }

  if (gameDetails.whiteIsolatedPawns.length !== 0) {
    gameDetails.blackAdvantageTags.push(
      `Isolated pawns: ${gameDetails.whiteIsolatedPawns.join(' / ')}`
    );
    gameDetails.totalTags.push(
      `Isolated pawns: ${gameDetails.whiteIsolatedPawns.join(' / ')}`
    );
    gameDetails.totalTags.flat();
  }
  if (gameDetails.blackIsolatedPawns.length !== 0) {
    gameDetails.whiteAdvantageTags.push(
      `Isolated pawns: ${gameDetails.blackIsolatedPawns.join(' / ')}`
    );
    gameDetails.totalTags.push(
      `Isolated pawns: ${gameDetails.blackIsolatedPawns.join(' / ')}`
    );
    gameDetails.totalTags.flat();
  }

  if (gameDetails.whitePassedPawns.length !== 0) {
    gameDetails.whiteAdvantageTags.push(
      `Passed pawns: ${gameDetails.whitePassedPawns.join(' / ')}`
    );
    gameDetails.totalTags.push(
      `Passed pawns: ${gameDetails.whitePassedPawns.join(' / ')}`
    );
    gameDetails.totalTags.flat();
  }

  if (gameDetails.blackPassedPawns.length !== 0) {
    gameDetails.blackAdvantageTags.push(
      `Passed pawns: ${gameDetails.blackPassedPawns.join(' / ')}`
    );
    gameDetails.totalTags.push(
      `Passed pawns: ${gameDetails.blackPassedPawns.join(' / ')}`
    );
    gameDetails.totalTags.flat();
  }
  return gameDetails;
};

export const findUnopposedBishopColour = (
  colour,
  unopposedBishopSquareColour,
  currentPosition
) => {
  for (let row = 0; row < 8; row++) {
    for (let column = 0; column < 8; column++) {
      if (currentPosition[row][column] === colour && (row + column) % 2 !== 0) {
        unopposedBishopSquareColour = 'dark';

        return unopposedBishopSquareColour;
        // return 'dark';
      } else if (
        currentPosition[row][column] === colour &&
        (row + column) % 2 === 0
      ) {
        unopposedBishopSquareColour = 'light';

        return unopposedBishopSquareColour;
        // return 'light';
      } else {
      }
    }
  }
};

export const findUnopposedBishopSquare = (
  colour,
  findPieces,
  findSquareColour,
  unopposedBishopSquareColour,
  unopposedBishopSquare,
  currentPosition
) => {
  if (colour === 'White') {
    findPieces('B', currentPosition).forEach(element => {
      if (findSquareColour(element) === unopposedBishopSquareColour) {
        unopposedBishopSquare = element;
      }
    });
  }
  if (colour === 'Black') {
    findPieces('b', currentPosition).forEach(element => {
      if (findSquareColour(element) === unopposedBishopSquareColour) {
        unopposedBishopSquare = element;
      }
    });
  }
  return unopposedBishopSquare;
};

export const checkTwoBishops = gameDetails => {
  let strPosition;
  let whiteBishopCount;
  let blackBishopCount;

  strPosition = gameDetails.currentPosition.flat(2).join();

  whiteBishopCount = (strPosition.match(/B/g) || []).length;
  blackBishopCount = (strPosition.match(/b/g) || []).length;

  // White bishop check

  if (whiteBishopCount === 2 && blackBishopCount === 1) {
    gameDetails.unopposedBishopSquareColour = findUnopposedBishopColour(
      'b',
      gameDetails.unopposedBishopSquareColour,
      gameDetails.currentPosition
    );

    gameDetails.whiteAdvantageTags.push('The Two Bishops');
    gameDetails.totalTags.push('The Two Bishops');

    gameDetails.whiteAdvantageTags.push(
      `Unopposed ${gameDetails.unopposedBishopSquareColour}-squared Bishop`
    );
    gameDetails.totalTags.push(
      `Unopposed ${gameDetails.unopposedBishopSquareColour}-squared Bishop`
    );

    return gameDetails;
  } else if (whiteBishopCount === 2 && blackBishopCount === 0) {
    gameDetails.whiteAdvantageTags.push('The Two Bishops');
    gameDetails.totalTags.push('The Two Bishops');

    return gameDetails;
  }

  // Black bishop check
  else if (blackBishopCount === 2 && whiteBishopCount === 1) {
    gameDetails.unopposedBishopSquareColour = findUnopposedBishopColour(
      'B',
      gameDetails.unopposedBishopSquareColour,
      gameDetails.currentPosition
    );

    gameDetails.blackAdvantageTags.push('The Two Bishops');
    gameDetails.totalTags.push('The Two Bishops');
    gameDetails.blackAdvantageTags.push(
      `Unopposed ${gameDetails.unopposedBishopSquareColour}-squared Bishop`
    );
    gameDetails.totalTags.push(
      `Unopposed ${gameDetails.unopposedBishopSquareColour}-squared Bishop`
    );

    return gameDetails;
  } else if (blackBishopCount === 2 && whiteBishopCount === 0) {
    gameDetails.blackAdvantageTags.push('The Two Bishops');
    gameDetails.totalTags.push('The Two Bishops');

    return gameDetails;
  } else {
    // return 'No two Bishops';
    return gameDetails;
  }
};

export const checkCentralPawnMajority = gameDetails => {
  // Check central two columns

  if (
    checkPieceInColumn('P', 'd', gameDetails.currentPosition) &&
    checkPieceInColumn('P', 'e', gameDetails.currentPosition) &&
    (checkPieceInColumn('p', 'd', gameDetails.currentPosition) === false ||
      checkPieceInColumn('p', 'e', gameDetails.currentPosition) === false)
  ) {
    gameDetails.whiteAdvantageTags.push('Central Pawn Majority');
    gameDetails.totalTags.push('Central Pawn Majority');

    gameDetails.centralPawnMajorityPawns = [
      ...(checkPieceInColumnArray('P', 'd', gameDetails.currentPosition) ?? []),
      ...(checkPieceInColumnArray('P', 'e', gameDetails.currentPosition) ?? []),
    ];
    // console.log(
    //   'Central Pawn Majority tags thing: ',
    //   gameDetails.whiteAdvantageTags
    // );
  } else if (
    checkPieceInColumn('p', 'd', gameDetails.currentPosition) &&
    checkPieceInColumn('p', 'e', gameDetails.currentPosition) &&
    (checkPieceInColumn('P', 'd', gameDetails.currentPosition) === false ||
      checkPieceInColumn('P', 'e', gameDetails.currentPosition) === false)
  ) {
    gameDetails.blackAdvantageTags.push('Central Pawn Majority');
    gameDetails.totalTags.push('Central Pawn Majority');

    gameDetails.centralPawnMajorityPawns = [
      ...(checkPieceInColumnArray('p', 'd', gameDetails.currentPosition) ?? []),
      ...(checkPieceInColumnArray('p', 'e', gameDetails.currentPosition) ?? []),
    ];
  } else {
  }

  // gameDetails.centralPawnMajorityPawns = [
  //   ...(checkPieceInColumnArray('P', 'd', gameDetails.currentPosition) ?? []),
  //   ...(checkPieceInColumnArray('P', 'e', gameDetails.currentPosition) ?? []),
  //   ...(checkPieceInColumnArray('p', 'd', gameDetails.currentPosition) ?? []),
  //   ...(checkPieceInColumnArray('p', 'e', gameDetails.currentPosition) ?? []),
  // ];

  return gameDetails;
};

export const checkDevelopment = gameDetails => {
  let whiteDevelopment = checkDevelopmentByColour(
    'white',
    gameDetails.currentPosition
  );
  let blackDevelopment = checkDevelopmentByColour(
    'black',
    gameDetails.currentPosition
  );

  if (
    whiteDevelopment - blackDevelopment > 1 &&
    whiteDevelopment - blackDevelopment < 3
  ) {
    gameDetails.whiteAdvantageTags.push('White is better developed');
    gameDetails.totalTags.push('White is better developed');
  }

  if (
    blackDevelopment - whiteDevelopment > 1 &&
    blackDevelopment - whiteDevelopment < 3
  ) {
    gameDetails.blackAdvantageTags.push('Black is better developed');
    gameDetails.totalTags.push('Black is better developed');
  }

  if (whiteDevelopment - blackDevelopment > 2) {
    gameDetails.whiteAdvantageTags.push('White is much better developed');
    gameDetails.totalTags.push('White is much better developed');
  }

  if (blackDevelopment - whiteDevelopment > 2) {
    gameDetails.blackAdvantageTags.push('Black is much better developed');
    gameDetails.totalTags.push('Black is much better developed');
  }

  return gameDetails;
};

export const checkUncastledKing = (gameDetails, fen, plyViewed) => {
  if (fen.split('K').length - 1 > 1 && plyViewed > 18) {
    gameDetails.blackAdvantageTags.push(`White's King is uncastled`);
    gameDetails.totalTags.push(`White's King is uncastled`);
  }
  if (fen.split('k').length - 1 > 1 && plyViewed > 19) {
    gameDetails.whiteAdvantageTags.push(`Black's King is uncastled`);
    gameDetails.totalTags.push(`White's King is uncastled`);
  }
  return gameDetails;
};

export const checkWeakSquaresBlack = (
  weakSquaresPatternsBlack,
  checkPattern,

  gameDetails
) => {
  let attackedSquares = [];

  for (let row = 5; row <= 6; row++) {
    for (let column = 0; column <= 7; column++) {
      if (gameDetails.currentPosition[row][column] === 'p') {
        // console.log('current position', currentPosition[row][column]);
        weakSquaresPatternsBlack.forEach(patternElement => {
          if (
            patternElement[0] ===
            'p' + convertColumnsToLetters(column) + (row + 1)
          ) {
            attackedSquares.push(patternElement[1]);
          }
        });
      }
    }
  }

  //Build weak squares array

  const toRemove = [...new Set(attackedSquares.join(' ').split(' ').sort())];

  const weakSquaresBlackStep1 = allSquaresBlack.filter(
    item => !toRemove.includes(item)
  );

  gameDetails.weakSquaresBlack = weakSquaresBlackStep1.filter(
    coords => retrievePiece(coords, gameDetails.currentPosition) !== 'p'
  );

  if (gameDetails.weakSquaresBlack.length > 0) {
    gameDetails.whiteAdvantageTags.push('Black has Weak Squares');
    gameDetails.totalTags.push('Black has Weak Squares');
  }

  return gameDetails;
};

export const checkWeakSquaresWhite = (
  weakSquaresPatternsWhite,
  checkPattern,
  gameDetails
) => {
  let attackedSquares = [];

  for (let row = 1; row <= 2; row++) {
    for (let column = 0; column <= 7; column++) {
      if (gameDetails.currentPosition[row][column] === 'P') {
        weakSquaresPatternsWhite.forEach(patternElement => {
          if (
            patternElement[0] ===
            'P' + convertColumnsToLetters(column) + (row + 1)
          ) {
            attackedSquares.push(patternElement[1]);
          }
        });
      }
    }
  }

  //Build weak squares array

  const toRemove = [...new Set(attackedSquares.join(' ').split(' ').sort())];

  const weakSquaresWhiteStep1 = allSquaresWhite.filter(
    item => !toRemove.includes(item)
  );

  gameDetails.weakSquaresWhite = weakSquaresWhiteStep1.filter(
    coords => retrievePiece(coords, gameDetails.currentPosition) !== 'P'
  );

  if (gameDetails.weakSquaresWhite.length > 0) {
    gameDetails.blackAdvantageTags.push('White has Weak Squares');
    gameDetails.totalTags.push('White has Weak Squares');
  }

  return gameDetails;
};

export const checkQueensideMajority = gameDetails => {
  let whiteQueensidePawns = [];
  let blackQueensidePawns = [];

  whiteQueensidePawns.push(
    checkPieceInColumnArray('P', 'a', gameDetails.currentPosition)
  );
  whiteQueensidePawns.push(
    checkPieceInColumnArray('P', 'b', gameDetails.currentPosition)
  );
  whiteQueensidePawns.push(
    checkPieceInColumnArray('P', 'c', gameDetails.currentPosition)
  );
  whiteQueensidePawns.push(
    checkPieceInColumnArray('P', 'd', gameDetails.currentPosition)
  );

  blackQueensidePawns.push(
    checkPieceInColumnArray('p', 'a', gameDetails.currentPosition)
  );
  blackQueensidePawns.push(
    checkPieceInColumnArray('p', 'b', gameDetails.currentPosition)
  );
  blackQueensidePawns.push(
    checkPieceInColumnArray('p', 'c', gameDetails.currentPosition)
  );
  blackQueensidePawns.push(
    checkPieceInColumnArray('p', 'd', gameDetails.currentPosition)
  );

  // console.log(whiteQueensidePawns.flat());

  whiteQueensidePawns = whiteQueensidePawns.filter(el => el.length > 0).flat();

  blackQueensidePawns = blackQueensidePawns.filter(el => el.length > 0).flat();

  if (whiteQueensidePawns.length > blackQueensidePawns.length) {
    gameDetails.whiteAdvantageTags.push('White Queenside pawn majority');
    gameDetails.totalTags.push('White Queenside pawn majority');
  }

  if (blackQueensidePawns.length > whiteQueensidePawns.length) {
    gameDetails.blackAdvantageTags.push('Black Queenside pawn majority');
    gameDetails.totalTags.push('Black Queenside pawn majority');
  }

  return gameDetails;
};

export const checkCrampedPosition = (gameDetails, plyViewed) => {
  // Check White
  // Count empty squares on 1st, 2nd and 3rd rows. Apply weights

  let firstWhiteRow = gameDetails.pgnWithLines.substr(56, 8);
  let secondWhiteRow = gameDetails.pgnWithLines.substr(48, 8);
  let thirdWhiteRow = gameDetails.pgnWithLines.substr(40, 8);

  let firstWhiteRowWeight =
    (8 - countCharacterInString('-', firstWhiteRow)) * 5;
  let secondWhiteRowWeight =
    (8 - countCharacterInString('-', secondWhiteRow)) * 3;
  let thirdWhiteRowWeight =
    (8 - countCharacterInString('-', thirdWhiteRow)) * 1;

  let whiteCrampedTotal = Math.round(
    (firstWhiteRowWeight + secondWhiteRowWeight + thirdWhiteRowWeight) * 1
  );

  // console.log('plyViewed', plyViewed);

  if (whiteCrampedTotal > 45 && plyViewed > 4) {
    // console.log('White is cramped');
    gameDetails.blackAdvantageTags.push('White is cramped');
    gameDetails.totalTags.push('White is cramped');
    gameDetails.whiteAdvisoryTags.push('Exchange off pieces (cramped)');
    gameDetails.totalTags.push('Exchange off pieces (cramped)');
  }

  // Check Black
  let firstBlackRow = gameDetails.pgnWithLines.substr(0, 8);
  let secondBlackRow = gameDetails.pgnWithLines.substr(8, 8);
  let thirdBlackRow = gameDetails.pgnWithLines.substr(17, 8);

  // console.log('firstBlackRow', firstBlackRow);
  // console.log('secondBlackRow', secondBlackRow);
  // console.log('thirdBlackRow', thirdBlackRow);

  let firstBlackRowWeight =
    (8 - countCharacterInString('-', firstBlackRow)) * 5;
  let secondBlackRowWeight =
    (8 - countCharacterInString('-', secondBlackRow)) * 3;
  let thirdBlackRowWeight =
    (8 - countCharacterInString('-', thirdBlackRow)) * 1;

  let blackCrampedTotal = Math.round(
    (firstBlackRowWeight + secondBlackRowWeight + thirdBlackRowWeight) * 1
  );

  // console.log('plyViewed', plyViewed);

  if (blackCrampedTotal > 45 && plyViewed > 4) {
    // console.log('Black is cramped');
    gameDetails.whiteAdvantageTags.push('Black is cramped');
    gameDetails.totalTags.push('Black is cramped');
    gameDetails.blackAdvisoryTags.push('Exchange off pieces (cramped)');
    gameDetails.totalTags.push('Exchange off pieces (cramped)');
  }

  return gameDetails;
};

export const checkWorsedPlacedPiece = (
  gameDetails,
  plyViewed,
  boardOrientation
) => {
  let worstPlacedPieceWhite = [];
  let worstPlacedPieceBlack = [];
  // console.log('table', gameDetails.currentPositionPieceValues);

  if (boardOrientation === 'b') {
    gameDetails.currentPositionPieceValues =
      gameDetails.currentPositionPieceValues
        .map(row => row.reverse())
        .reverse();
  }

  for (let column = 0; column <= 7; column++) {
    for (let row = 7; row >= 0; row--) {
      switch (gameDetails.currentPosition[row][column].charAt(0)) {
        case 'N':
          worstPlacedPieceWhite.push([
            'Knight',
            roundNumber(
              gameDetails.currentPositionPieceValues[row][column] - 3.3,
              1
            ),
            '' + convertColumnsToLetters(column) + (row + 1),
          ]);

          break;
        case 'B':
          worstPlacedPieceWhite.push([
            'Bishop',
            roundNumber(
              gameDetails.currentPositionPieceValues[row][column] - 3.6,
              1
            ),
            '' + convertColumnsToLetters(column) + (row + 1),
          ]);
          break;
        case 'R':
          worstPlacedPieceWhite.push([
            'Rook',
            roundNumber(
              gameDetails.currentPositionPieceValues[row][column] - 4.5,
              1
            ),
            '' + convertColumnsToLetters(column) + (row + 1),
          ]);
          break;
        case 'Q':
          worstPlacedPieceWhite.push([
            'Queen',
            roundNumber(
              gameDetails.currentPositionPieceValues[row][column] - 10.2,
              1
            ),
            '' + convertColumnsToLetters(column) + (row + 1),
          ]);
          break;
        case 'n':
          worstPlacedPieceBlack.push([
            'Knight',
            roundNumber(
              gameDetails.currentPositionPieceValues[row][column] - 3.3,
              1
            ),
            '' + convertColumnsToLetters(column) + (row + 1),
          ]);

          break;
        case 'b':
          worstPlacedPieceBlack.push([
            'Bishop',
            roundNumber(
              gameDetails.currentPositionPieceValues[row][column] - 3.6,
              1
            ),
            '' + convertColumnsToLetters(column) + (row + 1),
          ]);
          break;
        case 'r':
          worstPlacedPieceBlack.push([
            'Rook',
            roundNumber(
              gameDetails.currentPositionPieceValues[row][column] - 4.5,
              1
            ),
            '' + convertColumnsToLetters(column) + (row + 1),
          ]);
          break;
        case 'q':
          worstPlacedPieceBlack.push([
            'Queen',
            roundNumber(
              gameDetails.currentPositionPieceValues[row][column] - 10.2,
              1
            ),
            '' + convertColumnsToLetters(column) + (row + 1),
          ]);
          break;
        default:
      }
    }
  }

  if (boardOrientation === 'b') {
    gameDetails.currentPositionPieceValues =
      gameDetails.currentPositionPieceValues
        .map(row => row.reverse())
        .reverse();
  }

  worstPlacedPieceWhite.sort(function (a, b) {
    return a[1] - b[1];
  });

  worstPlacedPieceBlack.sort(function (a, b) {
    return a[1] - b[1];
  });

  if (plyViewed > 5) {
    // console.log(worstPlacedPieceWhite);
    // console.log(worstPlacedPieceBlack);

    if (worstPlacedPieceWhite.length > 0) {
      gameDetails.whiteAdvisoryTags.push(
        `Worst Placed Piece: ` +
          worstPlacedPieceWhite[0][0] +
          ' ' +
          worstPlacedPieceWhite[0][2]
      );
    }

    if (worstPlacedPieceBlack.length > 0) {
      gameDetails.blackAdvisoryTags.push(
        `Worst Placed Piece: ` +
          worstPlacedPieceBlack[0][0] +
          ' ' +
          worstPlacedPieceBlack[0][2]
      );
    }
    // gameDetails.totalTags.push('White is cramped');
  }

  // console.log('worstplacedpiece ', worstPlacedPiece);
  // console.log(
  //   'Worst Placed Piece: ' +
  //     worstPlacedPiece[0][0] +
  //     ' ' +
  //     worstPlacedPiece[0][2]
  // );

  return gameDetails;
};

export const checkDominateTheKnight = (
  gameDetails,
  plyViewed,
  boardOrientation
) => {
  // export const retrievePiece = (coords, currentPosition) => {

  let dominateTheKnightWhite = [];
  let dominateTheKnightBlack = [];

  // Check to dominate Black knights
  if (
    retrievePiece('g6', gameDetails.currentPosition) === 'n' &&
    retrievePiece('g2', gameDetails.currentPosition) === 'P'
  ) {
    gameDetails.whiteAdvisoryTags.push('Dominate the Knight on g6');
  }

  if (
    retrievePiece('b6', gameDetails.currentPosition) === 'n' &&
    retrievePiece('b2', gameDetails.currentPosition) === 'P'
  ) {
    gameDetails.whiteAdvisoryTags.push('Dominate the Knight on b6');
  }

  // Check to dominate White knights
  if (
    retrievePiece('g3', gameDetails.currentPosition) === 'N' &&
    retrievePiece('g7', gameDetails.currentPosition) === 'p'
  ) {
    gameDetails.blackAdvisoryTags.push('Dominate the Knight on g3');
  }

  if (
    retrievePiece('b3', gameDetails.currentPosition) === 'N' &&
    retrievePiece('b7', gameDetails.currentPosition) === 'p'
  ) {
    gameDetails.blackAdvisoryTags.push('Dominate the Knight on b3');
  }

  return gameDetails;
};

export const checkPawnProphylaxis = (
  whitePawnProphylaxisPatterns,
  blackPawnProphylaxisPatterns,
  checkPattern,
  gameDetails
) => {
  let pawnProphylaxisTags = [];
  let colour = 'White';

  whitePawnProphylaxisPatterns.forEach(patternElement => {
    // console.log('prophylaxis loop', patternElement);
    if (checkPattern(patternElement[1], gameDetails.currentPosition) === true) {
      pawnProphylaxisTags.push(patternElement[0]);
    }
  });

  if (pawnProphylaxisTags.length === 1 && colour === 'White') {
    gameDetails.whiteAdvisoryTags.push(
      `Prophylaxis: Consider playing ${pawnProphylaxisTags.join('')}`
    );
  }

  if (pawnProphylaxisTags.length > 1 && colour === 'White') {
    gameDetails.whiteAdvisoryTags.push(
      `Prophylaxis: Consider playing ${pawnProphylaxisTags.join(' / ')}`
    );
  }

  pawnProphylaxisTags = [];
  colour = 'Black';

  blackPawnProphylaxisPatterns.forEach(patternElement => {
    // console.log('prophylaxis loop', patternElement);
    if (checkPattern(patternElement[1], gameDetails.currentPosition) === true) {
      pawnProphylaxisTags.push(patternElement[0]);
    }
  });

  if (pawnProphylaxisTags.length === 1 && colour === 'Black') {
    gameDetails.blackAdvisoryTags.push(
      `Prophylaxis: Consider playing ${pawnProphylaxisTags.join('')}`
    );
  }

  if (pawnProphylaxisTags.length > 1 && colour === 'Black') {
    gameDetails.blackAdvisoryTags.push(
      `Prophylaxis: Consider playing ${pawnProphylaxisTags.join(' / ')}`
    );
  }

  return gameDetails;
};

export const checkKnightInvader = gameDetails => {
  let knightInvaders = [];

  // Invading Black Knights
  knightInvaders = checkPieceInRowArray('n', 3, gameDetails.currentPosition)
    .concat(checkPieceInRowArray('n', 2, gameDetails.currentPosition))
    .concat(checkPieceInRowArray('n', 1, gameDetails.currentPosition))
    .concat(checkPieceInRowArray('n', 0, gameDetails.currentPosition));

  if (knightInvaders.length === 1) {
    gameDetails.whiteAdvisoryTags.push(
      `Expel the invading Knight on ${knightInvaders[0]}`
    );
  }

  if (knightInvaders.length > 1) {
    gameDetails.whiteAdvisoryTags.push(
      `Expel the invading Knights on ${knightInvaders.join(' / ')}`
    );
  }

  // Invading White Knights

  knightInvaders = [];

  knightInvaders = checkPieceInRowArray('N', 4, gameDetails.currentPosition)
    .concat(checkPieceInRowArray('N', 5, gameDetails.currentPosition))
    .concat(checkPieceInRowArray('N', 6, gameDetails.currentPosition))
    .concat(checkPieceInRowArray('N', 7, gameDetails.currentPosition));

  if (knightInvaders.length === 1) {
    gameDetails.blackAdvisoryTags.push(
      `Expel the invading Knight on ${knightInvaders[0]}`
    );
  }

  if (knightInvaders.length > 1) {
    gameDetails.blackAdvisoryTags.push(
      `Expel the invading Knights on ${knightInvaders.join(' / ')}`
    );
  }

  return gameDetails;
};

export const checkConnectedRooks = gameDetails => {
  // let connectedRooks
  let connectedRooksTmp = [];
  let row = 0;
  let currentPiece = '';

  // Loop through row 1 for White
  for (let column = 0; column <= 7; column++) {
    currentPiece = gameDetails.currentPosition[row][column].charAt(0);
    // console.log(currentPiece);
    if (currentPiece === 'R') {
      connectedRooksTmp.push(convertColumnsToLetters(column) + 1);
    } else if (
      (currentPiece === 'N' ||
        currentPiece === 'B' ||
        currentPiece === 'Q' ||
        currentPiece === 'K' ||
        currentPiece === 'r' ||
        currentPiece === 'n' ||
        currentPiece === 'b' ||
        currentPiece === 'q' ||
        currentPiece === 'k') &&
      connectedRooksTmp.length > 0
    ) {
      gameDetails.connectedRooks = [];
      connectedRooksTmp = [];
      break;
    }

    // if (connectedRooksTmp.length > 1) {
    //   gameDetails.connectedRooks = connectedRooksTmp;
    //   break;
    // }
  }

  if (connectedRooksTmp.length > 1) {
    gameDetails.whiteAdvantageTags.push('White has connected Rooks');
    gameDetails.totalTags.push('White has connected Rooks');
  }

  row = 7;
  currentPiece = '';
  connectedRooksTmp = [];

  // Loop through row 8 for Black
  for (let column = 0; column <= 7; column++) {
    currentPiece = gameDetails.currentPosition[row][column].charAt(0);
    // console.log(currentPiece);
    if (currentPiece === 'r') {
      connectedRooksTmp.push(convertColumnsToLetters(column) + 1);
    } else if (
      (currentPiece === 'n' ||
        currentPiece === 'b' ||
        currentPiece === 'q' ||
        currentPiece === 'k' ||
        currentPiece === 'R' ||
        currentPiece === 'N' ||
        currentPiece === 'B' ||
        currentPiece === 'Q' ||
        currentPiece === 'K') &&
      connectedRooksTmp.length > 0
    ) {
      gameDetails.connectedRooks = [];
      connectedRooksTmp = [];
      break;
    }

    // if (connectedRooksTmp.length > 1) {
    //   gameDetails.connectedRooks = connectedRooksTmp;
    //   break;
    // }
  }

  if (connectedRooksTmp.length > 1) {
    gameDetails.blackAdvantageTags.push('Black has connected Rooks');
    gameDetails.totalTags.push('Black has connected Rooks');
  }

  return gameDetails;
};

export const checkLoosePiece = (gameDetails, chess, Chess, fen) => {
  // Loop through currentPosition
  // If White piece, add to array squares that piece attacks
  // Loop through currentPosition against
  // If White piece, does attack array contain that square?
  // If no, piece is loose
  let currentPiece;
  let currentSquare;
  let legalMovesLocal = [];
  let defendersCount = 0;
  let fenTmp;
  // let fen = chess.fen();
  let loosePiecesWhite = [];
  let loosePiecesBlack = [];

  // console.log('gamedetails', gameDetails);
  // console.log('fen', fen);

  const whitePieces = ['R', 'N', 'B', 'Q'];
  const blackPieces = ['r', 'n', 'b', 'q'];

  // fenTmp = chess.fen();
  fenTmp = fen;

  let chessTmp = new Chess();

  // console.log(legalMoves);

  // Find White loose pieces

  for (let column = 0; column <= 7; column++) {
    for (let row = 7; row >= 0; row--) {
      currentPiece = gameDetails.currentPosition[row][column].charAt(0);
      currentSquare = convertColumnsToLetters(column) + (row + 1);
      // console.log('currentSquare', currentSquare);
      // console.log(currentPiece, currentSquare);
      // Is there a white piece on the square?
      if (whitePieces.includes(currentPiece)) {
        // chess.load(fenTmp);
        fenTmp = fenTmp.replace(' b ', ' w ');
        chessTmp.load(fenTmp);
        // console.log('fenTmp after chessTmp.load(fenTmp', fenTmp);
        // console.log('currentSquare', currentSquare);
        // console.log('chessTmp', chessTmp);
        // console.log('fenTmp after chessTmp.load(fenTmp', fenTmp);

        // Replace White piece with Black Queen
        chessTmp.remove(currentSquare);
        // put queen in the square
        chessTmp.put(
          {
            type: chessTmp.QUEEN,
            color: chessTmp.BLACK,
          },
          currentSquare
        );

        // console.log('fenTmp after chessTmp.put queen  ', fenTmp);
        // console.log('chessTmp', chessTmp);

        // Check for all legal moves for captures
        // legalMovesLocal = chessTmp.moves();
        legalMovesLocal = chessTmp.moves({ verbose: true, legal: false });
        // console.log('legalMoves', legalMovesLocal);
        // If you get a match, push to loose piece array
        // defendersCount = legalMovesLocal.filter(
        //   m => m.to === currentSquare && m.flags === 'c'
        // ).length;
        defendersCount = legalMovesLocal.filter(
          m => m.to === currentSquare
        ).length;
        // console.log('defendersCount', defendersCount);

        if (defendersCount === 0 && row !== 0) {
          loosePiecesWhite.push(currentPiece + currentSquare);
          // console.log(
          //   'piece is loose',
          //   currentSquare,
          //   currentPiece,
          //   defendersCount
          // );
        }

        // chessTmp.remove(currentSquare);

        // chessTmp.put(
        //   {
        //     // put original piece back in the square
        //     // type: currentPiece.toLowerCase(),
        //     type: chessTmp.QUEEN,
        //     color: chessTmp.WHITE,

        //     // color: 'w',
        //   },
        //   currentSquare
        // );

        // chess.load(fen);
      }
    }
  }

  if (loosePiecesWhite.length > 0) {
    gameDetails.whiteAdvisoryTags.push(
      `Loose Pieces: ${loosePiecesWhite.join(' / ')}`
    );
  }

  // Find Black loose pieces

  fenTmp = fen;

  // let chessTmp = new Chess();

  // console.log(legalMoves);

  for (let column = 0; column <= 7; column++) {
    for (let row = 7; row >= 0; row--) {
      currentPiece = gameDetails.currentPosition[row][column].charAt(0);
      currentSquare = convertColumnsToLetters(column) + (row + 1);
      // console.log('currentSquare', currentSquare);
      // console.log(currentPiece, currentSquare);
      // Is there a white piece on the square?
      if (blackPieces.includes(currentPiece)) {
        // chess.load(fenTmp);
        fenTmp = fenTmp.replace(' w ', ' b ');
        chessTmp.load(fenTmp);
        // console.log('fenTmp after chessTmp.load(fenTmp', fenTmp);
        // console.log('currentSquare', currentSquare);
        // console.log('chessTmp', chessTmp);
        // console.log('fenTmp after chessTmp.load(fenTmp', fenTmp);

        // Replace White piece with Black Queen
        chessTmp.remove(currentSquare);
        // put queen in the square
        chessTmp.put(
          {
            type: chessTmp.QUEEN,
            color: chessTmp.WHITE,
          },
          currentSquare
        );

        // console.log('fenTmp after chessTmp.put queen  ', fenTmp);
        // console.log('chessTmp', chessTmp);

        // Check for all legal moves for captures
        // legalMovesLocal = chessTmp.moves();
        legalMovesLocal = chessTmp.moves({ verbose: true, legal: false });
        // console.log('legalMoves', legalMovesLocal);
        // If you get a match, push to loose piece array
        // defendersCount = legalMovesLocal.filter(
        //   m => m.to === currentSquare && m.flags === 'c'
        // ).length;
        defendersCount = legalMovesLocal.filter(
          m => m.to === currentSquare
        ).length;
        // console.log('defendersCount', defendersCount);

        if (defendersCount === 0 && row !== 7) {
          loosePiecesBlack.push(upperCase(currentPiece) + currentSquare);
        }
      }
    }
  }

  if (loosePiecesBlack.length > 0) {
    gameDetails.blackAdvisoryTags.push(
      `Loose Pieces: ${loosePiecesBlack.join(' / ')}`
    );
  }

  return gameDetails;
};
