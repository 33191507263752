import Box from '@material-ui/core/Box';
import AdvisorTags from './AdvisorTags';

export const AdvisorPanel = ({
  advisorTags,
  popupInstruction,
  highlightSquares,
  board,
  category,
  classes,
  gameDetails,
  tagScoreTabsPanelValue,
}) => {
  return (
    tagScoreTabsPanelValue === 0 && (
      <Box className={classes.paper}>
        <AdvisorTags
          // White Advantage tags
          advisorTags={gameDetails.whiteAdvantageTags}
          popupInstruction={popupInstruction}
          highlightSquares={highlightSquares}
          board={board}
          category="WhiteAdvantages"
          className={classes.paper}
          gameDetails={gameDetails}
        />

        <AdvisorTags
          // Black Advantage tags
          advisorTags={gameDetails.blackAdvantageTags}
          popupInstruction={popupInstruction}
          highlightSquares={highlightSquares}
          board={board}
          category="BlackAdvantages"
          className={classes.paper}
          gameDetails={gameDetails}
        />

        <AdvisorTags
          // White Advisory tags
          advisorTags={gameDetails.whiteAdvisoryTags}
          popupInstruction={popupInstruction}
          highlightSquares={highlightSquares}
          board={board}
          category="WhiteAdvisory"
          className={classes.paper}
          gameDetails={gameDetails}
        />

        <AdvisorTags
          // Black Advisory tags
          advisorTags={gameDetails.blackAdvisoryTags}
          popupInstruction={popupInstruction}
          highlightSquares={highlightSquares}
          board={board}
          category="BlackAdvisory"
          className={classes.paper}
          gameDetails={gameDetails}
        />

        <AdvisorTags
          // Neutral Advantage tags
          advisorTags={gameDetails.neutralTags}
          popupInstruction={popupInstruction}
          highlightSquares={highlightSquares}
          board={board}
          category="Neutral"
          className={classes.paper}
          gameDetails={gameDetails}
        />
      </Box>
    )
  );
};

export default AdvisorPanel;
