export const whiteKnightOutpostPatterns = [
  // First element is outpost location, second is pattern to match
  // a
  ['a5', 'Pb4 -a5 -b7 -b6'],
  ['a6', 'Pb5 -a6 -b7'],
  ['a7', 'Pb6 -a7'],
  // b
  ['b5', 'Pa4 -b5 -a7 -a6 -c7 -c6'],
  ['b5', 'Pc4 -b5 -a7 -a6 -c7 -c6'],
  ['b6', 'Pa5 -b6 -a7 -c7'],
  ['b6', 'Pc5 -b6 -a7 -c7'],
  ['b7', 'Pa6 -b7'],
  ['b7', 'Pc6 -b7'],
  // c
  ['c5', 'Pb4 -c5 -b7 -b6 -d7 -d6'],
  ['c5', 'Pd4 -c5 -b7 -b6 -d7 -d6'],
  ['c6', 'Pb5 -c6 -b7 -d7'],
  ['c6', 'Pd5 -c6 -b7 -d7'],
  ['c7', 'Pb6 -c7'],
  ['c7', 'Pd6 -c7'],
  // d
  ['d5', 'Pc4 -d5 -c7 -c6 -e7 -e6'],
  ['d5', 'Pe4 -d5 -c7 -c6 -e7 -e6'],
  ['d6', 'Pc5 -d6 -c7 -e7'],
  ['d6', 'Pe5 -d6 -c7 -e7'],
  ['d7', 'Pc6 -d7'],
  ['d7', 'Pe6 -d7'],
  // e
  ['e5', 'Pd4 -e5 -d7 -d6 -f7 -f6'],
  ['e5', 'Pf4 -e5 -d7 -d6 -f7 -f6'],
  ['e6', 'Pd5 -e6 -d7 -f7'],
  ['e6', 'Pf5 -e6 -d7 -f7'],
  ['e7', 'Pd6 -e7'],
  ['e7', 'Pf6 -e7'],
  // f
  ['f5', 'Pe4 -f5 -e7 -e6 -g7 -g6'],
  ['f5', 'Pg4 -f5 -e7 -e6 -g7 -g6'],
  ['f6', 'Pe5 -f6 -e7 -g7'],
  ['f6', 'Pg5 -f6 -e7 -g7'],
  ['f7', 'Pe6 -f7'],
  ['f7', 'Pg6 -f7'],
  // g
  ['g5', 'Pf4 -g5 -f7 -f6 -h7 -h6'],
  ['g5', 'Ph4 -g5 -f7 -f6 -h7 -h6'],
  ['g6', 'Pf5 -g6 -f7 -h7'],
  ['g6', 'Ph5 -g6 -f7 -h7'],
  ['g7', 'Pf6 -g7'],
  ['g7', 'Ph6 -g7'],
  // h
  ['h5', 'Pg4 -h5 -g7 -g6'],
  ['h6', 'Pg5 -h6 -g7'],
  ['h7', 'Pg6 -h7'],
];

export const blackKnightOutpostPatterns = [
  // First element is outpost location, second is pattern to match
  // a
  ['a4', 'pb5 -a4 -b2 -b3'],
  ['a3', 'pb4 -a3 -b2'],
  ['a2', 'pb3 -a2'],
  // b
  ['b4', 'pa5 -b4 -a2 -a3 -c2 -c3'],
  ['b4', 'pc5 -b4 -a2 -a3 -c2 -c3'],
  ['b3', 'pa4 -b3 -a2 -c3'],
  ['b3', 'pc4 -b3 -a2 -c3'],
  ['b2', 'pa3 -b2'],
  ['b2', 'pc3 -b2'],
  // c
  ['c4', 'pb5 -c4 -b2 -b3 -d2 -d3'],
  ['c4', 'pd5 -c4 -b2 -b3 -d2 -d3'],
  ['c3', 'pb4 -c3 -b2 -d2'],
  ['c3', 'pd4 -c3 -b2 -d2'],
  ['c2', 'pb3 -c2'],
  ['c2', 'pd3 -c2'],
  // d
  ['d4', 'pc5 -d4 -c2 -c3 -e2 -e3'],
  ['d4', 'pe5 -d4 -c2 -c3 -e2 -e3'],
  ['d3', 'pc4 -d3 -c2 -e2'],
  ['d3', 'pe4 -d3 -c2 -e2'],
  ['d2', 'pc3 -d2'],
  ['d2', 'pe3 -d2'],
  // e
  ['e4', 'pd5 -e4 -d2 -d3 -f2 -f3'],
  ['e4', 'pf5 -e4 -d2 -d3 -f2 -f3'],
  ['e3', 'pd4 -e3 -d2 -f2'],
  ['e3', 'pf4 -e3 -d2 -f2'],
  ['e2', 'pd3 -e2'],
  ['e2', 'pf3 -e2'],
  // f
  ['f4', 'pe5 -f4 -e2 -e3 -g2 -g3'],
  ['f4', 'pg5 -f4 -e2 -e3 -g2 -g3'],
  ['f3', 'pe4 -f3 -e2 -g2'],
  ['f3', 'pg4 -f3 -e2 -g2'],
  ['f2', 'pe3 -f2'],
  ['f2', 'pg3 -f2'],
  // g
  ['g4', 'pf5 -g4 -f2 -f3 -h2 -h3'],
  ['g4', 'ph5 -g4 -f2 -f3 -h2 -h3'],
  ['g3', 'pf4 -g3 -f2 -h2'],
  ['g3', 'ph4 -g3 -f2 -h2'],
  ['g2', 'pf3 -g2'],
  ['g2', 'ph3 -g2'],
  // h
  ['h4', 'pg5 -h4 -g2 -g3'],
  ['h3', 'pg4 -h3 -g2'],
  ['h2', 'pg3 -h2'],
];

export const openingPawnStructurePatterns = [
  ['Caro formation', 'Pd4 -e1 -e2 -e3 -e4 pc6 -c7 -d7 -d6 -d5 pe6'],
  ['Slav formation', '-c2 -c3 -c4 -c5 Pd4 Pd3 pc6 -d7 -d6 -d5 pe6'],
  ['Sicilian Scheveningen', '-c7 -c6 -c5 -d2 -d3 -d4 -d5 pd6 Pe4 pe6'],
  ['Sicilian Dragon', '-c7 -c6 -c5 -d2 -d3 -d4 -d5 pd6 pe6 pf7 pg6 Pe4'],
  ['Sicilian Sveshnikov', '-c7 -c6 -c5 -d2 -d3 -d4 -d5 pd6 pe5 -e6 Pe4'],
  ['Sicilian Maroczy Bind', '-c7 -c6 -c5 Pc4 -d2 -d3 -d4 -d5 pd7 pe6 -e5 Pe4'],
  ['Sicilian Maroczy Bind', '-c7 -c6 -c5 -d2 -d3 -d4 -d5 pd6 pe5 Pc4 Pe4'],
  ['Hedgehog', 'pa6 pb6 pd6 pe6 pf7 pg7 Pb2 Pc4 -d2 -d3 -d4 -d5 Pe4'],
  [
    'Rauzer formation',
    'pb7 pc6 -d7 -d6 -d5 pe5 pf7 Pc4 -c3 -c2 -d2 -d3 -d4 -d5 Pe4 Pf7',
  ],
  ['d5 chain formation', 'Pd5 Pe4 pd6 pe5'],
  ['e5 chain formation', 'Pd4 Pe5 pd5 pe6'],
  ['Modern Benoni formation', 'pc5 -c6 -c7 pd6 -d7 Pc4 Pd5'],
  ['Modern Benoni formation', 'pc5 -c6 -c7 pd7 -d7 Pc4 Pd5'],
  ['Modern Benoni formation', 'pc5 -c6 -c7 pd6 -d7 Pd5 Pe4'],
  ['Modern Benoni formation', 'pc5 -c6 -c7 pd7 -d7 Pd5 Pe4'],
  ['Carlsbad formation', 'Pd4 Pe3 -c2 -c3 -c4 -c5 pc6 pd5 -e7 -e6 -e5 -e4'],
  [
    'Panov formation',
    'pb7 -c7 -c6 pd5 -d6 pe6 pf7 Pc5 -c4 -c3 Pd4 -e5 -e4 -e3',
  ],
  ['Stonewall formation', 'pd5 pe6 pf5 -e5 Pd4 Pe3 Pf4 -e4'],
  ['Botvinnik system', 'pc5 pd6 pe5 -d5 Pc4 Pd3 Pe4 -d4'],
];

export const kingSafetyPawnStructurePatterns = [
  ['Weakened pawns by Black King', 'kg8 pf7 pg7 ph6', 'white advantage'],
  ['Weakened pawns by Black King', 'kh8 pf7 pg7 ph6', 'white advantage'],
  ['Weakened pawns by Black King', 'kg8 pf6 pg7 ph7', 'white advantage'],
  ['Weakened pawns by Black King', 'kh8 pf6 pg7 ph7', 'white advantage'],
  ['Weakened pawns by Black King', 'kg8 -f7 pg7 ph7', 'white advantage'],
  ['Weakened pawns by Black King', 'kh8 -f7 pg7 ph7', 'white advantage'],
  ['Weakened pawns by Black King', 'kg8 pf7 -g7 ph7', 'white advantage'],
  ['Weakened pawns by Black King', 'kh8 pf7 -g7 ph7', 'white advantage'],
  ['Weakened pawns by Black King', 'kg8 pf6 pg7 -h7', 'white advantage'],
  ['Weakened pawns by Black King', 'kh8 pf6 pg7 -h7', 'white advantage'],
  ['Weak pawns by Black King', 'kg8 pf7 pg5 ph6', 'white advantage'],
  ['Weak pawns by Black King', 'kh8 pf7 pg5 ph6', 'white advantage'],
  ['Weakened pawns by White King', 'Kg1 Pf2 Pg2 Ph3', 'black advantage'],
  ['Weakened pawns by White King', 'Kh1 Pf2 Pg2 Ph3', 'black advantage'],
  ['Weakened pawns by White King', 'Kg1 Pf3 Pg2 Ph2', 'black advantage'],
  ['Weakened pawns by White King', 'Kh1 Pf3 Pg2 Ph2', 'black advantage'],
  ['Weak pawns by White King', 'Kg1 Pf2 Pg4 Ph3', 'black advantage'],
  ['Weak pawns by White King', 'Kh1 Pf2 Pg4 Ph3', 'black advantage'],
];

export const miscPawnStructurePatterns = [
  [
    'Black has Little Center',
    'Pd4 -e2 -e3 -e4 -e5 -d5 -d6 -d7 pe6',
    'white advantage',
  ],
  [
    'Black has Little Center',
    'Pe4 -d2 -d3 -d4 -d5 -e5 -e6 -e7 pd6',
    'white advantage',
  ],
  [
    'White has Little Center',
    'Pd3 -e2 -e3 -e4 pe5 -d4 -d5 -d6 -d7',
    'black advantage',
  ],
  [
    'White has Little Center',
    'Pe3 -d2 -d3 -d4 pd5 -e4 -e5 -e6 -e7',
    'black advantage',
  ],
  [
    'Back rank mate risk for Black',
    'kg8 pf7 pg7 ph7 =f8 =e8',
    'white advantage',
  ],
  [
    'Back rank mate risk for Black',
    'kh8 pf7 pg7 ph7 =g8 =f8 =e8',
    'white advantage',
  ],
  [
    'Back rank mate risk for White',
    'Kg1 Pf2 Pg2 Ph2 =f1 =e1',
    'black advantage',
  ],
  [
    'Back rank mate risk for White',
    'Kh1 Pf2 Pg2 Ph2 =g1 =f1 =e1',
    'black advantage',
  ],
  // ['White has bad dark-squared Bishop', 'Bc1 Pd2', 'black advantage'],
  // ['White has bad dark-squared Bishop', 'Bb2 Pc3', 'black advantage'],
  // ['White has bad dark-squared Bishop', 'Bc1 Pe3 Pb2', 'black advantage'],
  // ['White has bad dark-squared Bishop', 'Bc1 Pe3 Pa3', 'black advantage'],
  // ['White has bad dark-squared Bishop', 'Bb2 Pd4', 'black advantage'],
  // [`White's King is uncastled`, 'Ke1', 'black advantage'],
  // [`Black's King is uncastled`, 'ke8', 'white advantage'],
  [`White's King in center`, 'Kd1', 'black advantage'],
  [`White's King in center`, 'Kd2', 'black advantage'],
  [`White's King in center`, 'Ke2', 'black advantage'],
  [`Black's King in center`, 'kd8', 'white advantage'],
  [`Black's King in center`, 'kd7', 'white advantage'],
  [`Black's King in center`, 'ke7', 'white advantage'],
];

export const weakSquaresPatternsWhite = [
  // What pawn attacks what squares?

  ['Pa2', 'b3 b4'],
  ['Pa3', 'b4'],
  ['Pb2', 'a3 c3 a4 c4'],
  ['Pb3', 'a4 c4'],
  ['Pc2', 'b3 d3 b4 d4'],
  ['Pc3', 'b4 d4'],
  ['Pd2', 'c3 e3 c4 e4'],
  ['Pd3', 'c4 e4'],
  ['Pe2', 'd3 f3 d4 f4'],
  ['Pe3', 'd4 f4'],
  ['Pf2', 'e3 g3 e4 g4'],
  ['Pf3', 'e4 g4'],
  ['Pg2', 'f3 h3 f4 h4'],
  ['Pg3', 'f4 h4'],
  ['Ph2', 'g3 g4'],
  ['Ph3', 'g4'],
];

export const weakSquaresPatternsBlack = [
  // What pawn attacks what squares?

  ['pa7', 'b6 b5'],
  ['pa6', 'b5'],
  ['pb7', 'a6 c6 a5 c5'],
  ['pb6', 'a5 c5'],
  ['pc7', 'b6 d6 b5 d5'],
  ['pc6', 'b5 d5'],
  ['pd7', 'c6 e6 c5 e5'],
  ['pd6', 'c5 e5'],
  ['pe7', 'd6 f6 d5 f5'],
  ['pe6', 'd5 f5'],
  ['pf7', 'e6 g6 e5 g5'],
  ['pf6', 'e5 g5'],
  ['pg7', 'f6 h6 f5 h5'],
  ['pg6', 'f5 h5'],
  ['ph7', 'g6 g5'],
  ['ph6', 'g5'],
];

export const allSquaresWhite = [
  'a3',
  'a4',
  'b3',
  'b4',
  'c3',
  'c4',
  'd3',
  'd4',
  'e3',
  'e4',
  'f3',
  'f4',
  'g3',
  'g4',
  'h3',
  'h4',
];

export const allSquaresBlack = [
  'a6',
  'a5',
  'b6',
  'b5',
  'c6',
  'c5',
  'd6',
  'd5',
  'e6',
  'e5',
  'f6',
  'f5',
  'g6',
  'g5',
  'h6',
  'h5',
];

export const whitePawnProphylaxisPatterns = [
  // First element is pawn move to do, second is pattern to match

  ['a4', 'Pa2 pa6 pb7 -c6'],
  ['a4', 'Pa2 pa6 pb6 -c6'],
  ['a4', 'Pa3 pa6 pb7 -c6'],
  ['a4', 'Pa3 pa6 pb6 -c6'],
  ['h4', 'Ph2 ph6 pg7 -f6'],
  ['h4', 'Ph2 ph6 pg6 -f6'],
  ['h4', 'Ph3 ph6 pg7 -f6'],
  ['h4', 'Ph3 ph6 pg6 -f6'],
  ['h5', 'Ph4 pg7 ph6 -f7'],
  ['a5', 'Pa4 pb7 pa6 -c7'],
];

export const blackPawnProphylaxisPatterns = [
  // First element is pawn move to do, second is pattern to match

  ['a5', 'pa7 Pa3 Pb2 -c3'],
  ['a5', 'pa7 Pa3 Pb3 -c3'],
  ['a5', 'pa6 Pa3 Pb2 -c3'],
  ['a5', 'pa6 Pa3 Pb3 -c3'],
  ['h5', 'ph7 Ph3 Pg2 -f3'],
  ['h5', 'ph7 Ph3 Pg3 -f3'],
  ['h5', 'ph6 Ph3 Pg2 -f3'],
  ['h5', 'ph6 Ph3 Pg3 -f3'],
  ['h4', 'ph5 Pg2 Ph3 -f2'],
  ['a4', 'pa5 Pb2 Pa3 -c2'],
];
