import React from 'react';
import { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import {
  Chessboard,
  // INPUT_EVENT_TYPE,
  // MOVE_INPUT_MODE,
  // COLOR,
} from 'cm-chessboard';

export const UncastledKingWhite = props => {
  // {props.tagText}

  useEffect(() => {
    new Chessboard(document.getElementById('board3'), {
      position: props.fen,
      // 'rn2k1r1/ppp1pp1p/3p2p1/5bn1/P7/2N2B2/1PPPPP2/2BNK1RR w Gkq - 4 11',
      // orientation: COLOR.black,
    });
  }, []);

  return (
    <>
      <DialogTitle id="helper-dialog-title">
        <Typography variant="h5">The Uncastled White King</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">White's Strategy</Typography>
        <Typography paragraph="true">
          If you are on move 10 or more and have still not castled, consider it
          a priority. Castling usually improves King safety.
        </Typography>
        <Typography variant="h6">Black's Strategy</Typography>
        <Typography paragraph="true">
          Try to keep the White King in the centre. Also, try to open up the
          position so the White King becomes exposed and vulnerable to attack.
        </Typography>
        <Typography variant="h6">Notes</Typography>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography paragraph="true">
              An uncastled King in the middlegame is often risky. It is
              generally advised to tuck your King away to safety by castling
              before the middlegame starts.
            </Typography>
            <Typography paragraph="true">
              Castling short to the Kingside is most common. Castling to the
              Queenside can lead to wild attacking games if your opponent has
              castled on the opposite side to you.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Paper width="5%" id="board3"></Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export const UncastledKingBlack = props => {
  // {props.tagText}

  useEffect(() => {
    new Chessboard(document.getElementById('board3'), {
      position: props.fen,
      // 'rn2k1r1/ppp1pp1p/3p2p1/5bn1/P7/2N2B2/1PPPPP2/2BNK1RR w Gkq - 4 11',
      // orientation: COLOR.black,
    });
  }, []);

  return (
    <>
      <DialogTitle id="helper-dialog-title">
        <Typography variant="h5">The Uncastled Black King</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">White's Strategy</Typography>
        <Typography paragraph="true">
          Try to keep the Black King in the centre. Also, try to open up the
          position so the Black King becomes exposed and vulnerable to attack.
        </Typography>
        <Typography variant="h6">Black's Strategy</Typography>
        <Typography paragraph="true">
          If you are on move 10 or more and have still not castled, consider it
          a priority. Castling usually improves King safety.
        </Typography>
        <Typography variant="h6">Notes</Typography>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography paragraph="true">
              An uncastled King in the middlegame is often risky. It is
              generally advised to tuck your King away to safety by castling
              before the middlegame starts.
            </Typography>
            <Typography paragraph="true">
              Castling short to the Kingside is most common. Castling to the
              Queenside can lead to wild attacking games if your opponent has
              castled on the opposite side to you.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Paper width="5%" id="board3"></Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};
