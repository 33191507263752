import React, { useState, useEffect, useRef } from 'react';
import Chess from 'chess.js';
import Header from './ui/Header';
import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter, Route, Switch as SwitchBR } from 'react-router-dom';
import theme from './ui/Theme';
import Footer from './ui/Footer';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import LastPageIcon from '@material-ui/icons/LastPage';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TagContent from './TagContent';
import MoveList from './MoveList';
import MoveButtons from './MoveButtons';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';

import { useHistory } from 'react-router-dom';
// import { withStyles } from '@material-ui/core/styles';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import Icon from '@material-ui/core/Icon';
// import IconButton from '@material-ui/core/IconButton';
// import AppBar from '@material-ui/core/AppBar';
// import Chip from '@material-ui/core/Chip';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import Modal from '@material-ui/core/Modal';
// import { NavigationButtons } from './components/board';
// import SimpleDialog from './components/SimpleDialog';
// import Link from '@material-ui/core/Link';
// import { myComponent } from './components/TagPanel';

import AdvisorTags from './AdvisorTags';
import AdvisorPanel from './AdvisorPanel';
// import {
//   firstMove,
//   previousMove,
//   nextMove,
//   lastMove,
// } from '../library/moveButtonsCode';
// import AnalyseGame from './AnalyseGame';
import { pgnExpanded, addPgnToArray } from '../library/utilities';
import {
  showPieceValues,
  removePieceValues,
  calculatePieceValues,
} from '../library/showPieceValues';
import { highlightSquares } from '../library/highlightSquares';
import { questionBuilder } from '../library/questionBuilder';

import {
  Chessboard,
  INPUT_EVENT_TYPE,
  // MOVE_INPUT_MODE,
  SQUARE_SELECT_TYPE,
  MARKER_TYPE,
  COLOR,
} from 'cm-chessboard';
// } from 'cm-chessboard/Chessboard.js';

// import { ChessboardView } from '../cm-chessboard/ChessboardView.js';

import '../styles/cm-chessboard.css';
import { gameAnalyser } from '../library/gameAnalyser';
// import moveHistory from './components/MoveList';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    height: '100%',
  },
  paperTagsSection: {
    // padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    height: '100%',
  },
  marginButton: {
    // margin: theme.spacing(0),
    marginRight: 10,
  },
  listItemText: {
    fontSize: '1.2em', //Insert your required size
    // padding: '0',
  },
  listItem: {
    // fontSize: '1.2em', //Insert your required size
    // padding: '0',
  },
  dialog: {
    position: 'absolute',
    top: 50,
    backgroundColor: '#f5f5f5',
    marginTop: 10,
  },
  // dialogTitle: {
  //   position: 'absolute',

  //   top: 50,
  //   backgroundColor: '#eeeeee',
  // },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  customizedButton: {
    position: 'absolute',
    left: '60',
    top: '20px',
    color: 'silver',
  },
}));

let chess = new Chess();

console.clear();
let fenHistory = [];
let plyViewed = 0;
let currentTag = '';
let tagColour = '';
let boardOrientation = 'w';
// let passedGamesFlag = 0;

// export const handleReceiveSelectedGame = () => {
//   // alert(importedPGN);
//   newGame();
//   chess.load_pgn(importedPGN);
//   plyViewed = 0;

//   setHistory(chess.history());

//   const auxChess = new Chess();

//   // const fenHistory = [];
//   fenHistory = [];
//   fenHistory.push(auxChess.fen());
//   chess.history().forEach(move => {
//     auxChess.move(move);
//     fenHistory.push(auxChess.fen());
//   });

//   setOpenImportPGN(false);
// };

const AnalyseGame = props => {
  const classes = useStyles();

  const pageHistory = useHistory();

  // console.log('chess', chess);

  //Set states

  const [fen, setFen] = useState(
    'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'
  );
  const [history, setHistory] = useState([]);
  // const [squareStyles, setSquareStyles] = useState('');
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [openImportPGN, setOpenImportPGN] = React.useState(false);
  const [importedPGN, setImportedPGN] = useState('');
  const [openTagInstructions, setOpenTagInstructions] = React.useState(false);
  const [switchState, setSwitchState] = React.useState({
    checkedA: true,
    checkedB: true,
  });
  const [tagScoreTabsPanelValue, setTagScoreTabsPanelValue] = React.useState(0);

  const handleTagScoreTabsPanel = (event, newValue) => {
    setTagScoreTabsPanelValue(newValue);
  };

  const handleOpenTagInstructions = () => {
    setOpenTagInstructions(true);
  };

  const handleCloseTagInstructions = () => {
    setOpenTagInstructions(false);
  };

  const handleChangeSwitches = event => {
    setSwitchState({
      ...switchState,
      [event.target.name]: event.target.checked,
    });
  };

  const changePlyViewedValue = newValue => {
    plyViewed = newValue;
    props.setCurrentPlyViewed(newValue);
  };

  // Setup sound
  const moveSound = new Audio('Move.wav');
  const captureSound = new Audio('Capture.wav');
  const castleSound = new Audio('Castle.wav');

  let buttonWidth = '106px';

  let gameDetails = {
    blackKingSquare: '',
    whiteKingSquare: '',
    unopposedBishopSquareColour: '',
    unopposedBishopSquare: '',
    centralPawnMajority: '',
    openFiles: [],
    whiteSemiOpenFiles: [],
    blackSemiOpenFiles: [],
    whiteKnightOutpostSquares: [],
    blackKnightOutpostSquares: [],
    whitePassedPawns: [],
    blackPassedPawns: [],
    whiteIsolatedPawns: [],
    blackIsolatedPawns: [],
    whiteDoubledPawns: [],
    blackDoubledPawns: [],
    centralPawnMajorityPawns: [],
    weakSquaresWhite: [],
    weakSquaresBlack: [],
    currentPosition: [[], [], [], [], [], [], [], []],
    whiteAdvantageTags: [],
    blackAdvantageTags: [],
    neutralTags: [],
    totalTags: [],
    currentPositionPieceValues: [[], [], [], [], [], [], [], []],
    pgnWithLines: '',
    gamePgnArray: [],
    whiteAdvisoryTags: [],
    blackAdvisoryTags: [],
    // connectedRooks: [],
  };

  // const newGame = () => {
  //   gameDetails = {
  //     blackKingSquare: '',
  //     whiteKingSquare: '',
  //     unopposedBishopSquareColour: '',
  //     unopposedBishopSquare: '',
  //     centralPawnMajority: '',
  //     openFiles: [],
  //     whiteSemiOpenFiles: [],
  //     blackSemiOpenFiles: [],
  //     whiteKnightOutpostSquares: [],
  //     blackKnightOutpostSquares: [],
  //     whitePassedPawns: [],
  //     blackPassedPawns: [],
  //     whiteIsolatedPawns: [],
  //     blackIsolatedPawns: [],
  //     whiteDoubledPawns: [],
  //     blackDoubledPawns: [],
  //     centralPawnMajorityPawns: [],
  //     weakSquaresWhite: [],
  //     weakSquaresBlack: [],
  //     currentPosition: [[], [], [], [], [], [], [], []],
  //     whiteAdvantageTags: [],
  //     blackAdvantageTags: [],
  //     neutralTags: [],
  //     totalTags: [],
  //     currentPositionPieceValues: [[], [], [], [], [], [], [], []],
  //     pgnWithLines: '',
  //     gamePgnArray: [],
  //     whiteAdvisoryTags: [],
  //     blackAdvisoryTags: [],
  //     // connectedRooks: [],
  //   };

  //   plyViewed = 0;
  //   let startingPositionChoice = '';

  //   if (window.confirm('Load middlegame position?')) {
  //     startingPositionChoice = 'middlegame';
  //     setFen(
  //       'r1bqk2r/4bp1p/p1np1p2/1p1Np3/4P3/N6P/PPP2PP1/R2QKB1R b KQkq - 0 11'
  //     );
  //   } else {
  //     startingPositionChoice = 'start';
  //     setFen('rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1');
  //   }

  //   setHistory([]);
  //   setSelectedIndex(0);

  //   chess.reset();
  //   chess.load(
  //     'r1bqk2r/4bp1p/p1np1p2/1p1Np3/4P3/N6P/PPP2PP1/R2QKB1R b KQkq - 0 11'
  //   );
  //   fenHistory = [];
  //   fenHistory.push(chess.fen());

  //   if (startingPositionChoice === 'middlegame') {
  //     board.current.setPosition(
  //       'r1bqk2r/4bp1p/p1np1p2/1p1Np3/4P3/N6P/PPP2PP1/R2QKB1R w KQkq - 0 11'
  //     );
  //   } else {
  //     board.current.setPosition('start');
  //   }
  // };

  // // Set pgn to that passed by Games pageSize
  // if (props.passedGamesFlag === 1) {
  //   alert(props.passedGamesFlag);
  //   // if (passedGamesFlag === 1) {
  //   // passedGamesFlag = 0;
  //   alert('yo');

  //   newGame();
  //   chess.load_pgn(importedPGN);
  //   plyViewed = 0;

  //   setHistory(chess.history());

  //   const auxChess = new Chess();

  //   // const fenHistory = [];
  //   fenHistory = [];
  //   fenHistory.push(auxChess.fen());
  //   chess.history().forEach(move => {
  //     auxChess.move(move);
  //     fenHistory.push(auxChess.fen());
  //   });
  // }

  // None standard pgn with lines instead of numbers
  gameDetails.pgnWithLines = pgnExpanded(fen);

  gameDetails.gamePgnArray = addPgnToArray(gameDetails);

  // Handles the move
  const inputHandler = event => {
    if (event.type === INPUT_EVENT_TYPE.moveDone) {
      // Check that game is at end and not viewing earlier moves

      const move = { from: event.squareFrom, to: event.squareTo };
      const result = chess.move(move);
      // legalMoves = chess.moves();
      // console.log(checkPieceMobilityCount('c1', chess));

      if (result) {
        event.chessboard.setPosition(chess.fen());
        setFen(chess.fen());
        setHistory(chess.history());

        fenHistory.push(chess.fen());
        plyViewed++;

        // Set persistent move marker
        // setSelectedIndex(props.setCurrentPlyViewed);
        setSelectedIndex(plyViewed);

        // Set pgn

        //Play sound for move
        if (result.flags === 'c') {
          captureSound.play();
        } else if (result.flags === 'k') {
          castleSound.play();
        } else {
          moveSound.play();
        }
      } else {
        console.warn('invalid move', move);
      }

      return result;
    } else {
      return true;
    }
  };

  const board = useRef();

  // useEffect(() => {
  //   // let current;

  //   if (!board.current) {
  //     // console.log('the !board.current got triggered');
  //     board.current = new Chessboard(document.getElementById('board'), {
  //       // position: 'r4r1k/p1P1qppp/1p6/8/5B2/2Q1P3/P4PPP/R3KB1R b KQ - 0 21',
  //       position: chess.fen(),
  //       animationDuration: 230,
  //       style: {
  //         cssClass: 'default',
  //         moveFromMarker: MARKER_TYPE.frame, // the marker used to mark the start square
  //         moveToMarker: MARKER_TYPE.frame, // the marker used to mark the square where the figure is moving to}.
  //       },
  //       sprite: {
  //         url: './chessboard-sprite.svg', // pieces and markers are stored as svg in the sprite
  //         grid: 40, // sprite is tiled with one piece every 40px

  //         cache: true,
  //       },
  //       orientation: COLOR.white,
  //       // moveInputMode: MOVE_INPUT_MODE.dragPiece,
  //       responsive: true,
  //     });

  //     fenHistory.push(chess.fen());
  //     // console.log('fenHistoryfirstonly', fenHistory);

  //     // Enables moves via user imput
  //     board.current.enableMoveInput(inputHandler);

  //     board.current.enableSquareSelect(event => {
  //       switch (event.type) {
  //         case SQUARE_SELECT_TYPE.primary:
  //           // left click
  //           break;
  //         case SQUARE_SELECT_TYPE.secondary:
  //           // right click
  //           break;
  //         default:
  //       }
  //     });
  //   }
  //   // console.log(
  //   //   'the use effect got triggered by avoided the !board.current code'
  //   // );
  // }, []);
  useEffect(() => {
    if (!board.current) {
      board.current = new Chessboard(document.getElementById('board'), {
        position: chess.fen(),
        sprite: { url: './chessboard-sprite.svg' },
        style: { moveFromMarker: undefined, moveToMarker: undefined }, // disable standard markers
        orientation: COLOR.white,
      });

      fenHistory.push(chess.fen());
      board.current.enableMoveInput(inputHandler);
    }
  }, []);

  // Build the arrays with tags
  gameDetails = gameAnalyser(
    gameDetails,
    fen,
    plyViewed,
    boardOrientation,
    chess,
    fenHistory
  );

  // Display piece values after everything has rendered
  useEffect(() => {
    if (switchState.checkedA) {
      showPieceValues(gameDetails, boardOrientation, chess, fenHistory);
    } else {
      removePieceValues();
    }
  });

  //TODO: in progress ***
  // questionBuilder(
  //   gameDetails.whiteAdvantageTags,
  //   gameDetails.blackAdvantageTags,
  //   fenHistory
  // );

  // Open dialog on click chip

  const popupInstruction = (name1, colour) => {
    // alert(name1);
    currentTag = name1;
    tagColour = colour;
    handleOpenTagInstructions();
  };

  const newGame = () => {
    gameDetails = {
      blackKingSquare: '',
      whiteKingSquare: '',
      unopposedBishopSquareColour: '',
      unopposedBishopSquare: '',
      centralPawnMajority: '',
      openFiles: [],
      whiteSemiOpenFiles: [],
      blackSemiOpenFiles: [],
      whiteKnightOutpostSquares: [],
      blackKnightOutpostSquares: [],
      whitePassedPawns: [],
      blackPassedPawns: [],
      whiteIsolatedPawns: [],
      blackIsolatedPawns: [],
      whiteDoubledPawns: [],
      blackDoubledPawns: [],
      centralPawnMajorityPawns: [],
      weakSquaresWhite: [],
      weakSquaresBlack: [],
      currentPosition: [[], [], [], [], [], [], [], []],
      whiteAdvantageTags: [],
      blackAdvantageTags: [],
      neutralTags: [],
      totalTags: [],
      currentPositionPieceValues: [[], [], [], [], [], [], [], []],
      pgnWithLines: '',
      gamePgnArray: [],
      whiteAdvisoryTags: [],
      blackAdvisoryTags: [],
      // connectedRooks: [],
    };

    plyViewed = 0;
    let startingPositionChoice = '';

    if (window.confirm('Load middlegame position?')) {
      startingPositionChoice = 'middlegame';
      setFen(
        'r1bqk2r/4bp1p/p1np1p2/1p1Np3/4P3/N6P/PPP2PP1/R2QKB1R b KQkq - 0 11'
      );
    } else {
      startingPositionChoice = 'start';
      setFen('rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1');
    }

    setHistory([]);
    setSelectedIndex(0);

    chess.reset();
    chess.load(
      'r1bqk2r/4bp1p/p1np1p2/1p1Np3/4P3/N6P/PPP2PP1/R2QKB1R b KQkq - 0 11'
    );
    fenHistory = [];
    fenHistory.push(chess.fen());

    if (startingPositionChoice === 'middlegame') {
      board.current.setPosition(
        'r1bqk2r/4bp1p/p1np1p2/1p1Np3/4P3/N6P/PPP2PP1/R2QKB1R w KQkq - 0 11'
      );
    } else {
      board.current.setPosition('start');
    }
  };

  const importPGNPopulateHistory = () => {
    fenHistory = chess.history();
  };

  const handleClickImportPGN = () => {
    setOpenImportPGN(true);
  };

  const handleCloseImportPGN = () => {
    // alert(importedPGN);
    newGame();
    chess.load_pgn(importedPGN);
    plyViewed = 0;

    setHistory(chess.history());

    const auxChess = new Chess();

    // const fenHistory = [];
    fenHistory = [];
    fenHistory.push(auxChess.fen());
    chess.history().forEach(move => {
      auxChess.move(move);
      fenHistory.push(auxChess.fen());
    });

    setOpenImportPGN(false);
  };

  const handleReceiveSelectedGame = () => {
    newGame();
    chess.load_pgn(importedPGN);
    plyViewed = 0;

    setHistory(chess.history());

    const auxChess = new Chess();

    // const fenHistory = [];
    fenHistory = [];
    fenHistory.push(auxChess.fen());
    chess.history().forEach(move => {
      auxChess.move(move);
      fenHistory.push(auxChess.fen());
    });

    setOpenImportPGN(false);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  // Move through game buttons functions

  const firstMove = () => {
    if (plyViewed > 0) {
      plyViewed = 0;
      setFen(fenHistory[plyViewed]);
      setSelectedIndex(plyViewed);
      board.current.setPosition(fenHistory[plyViewed]);
    }
  };

  const previousMove = () => {
    if (plyViewed > 0) {
      plyViewed--;
      board.current.setPosition(fenHistory[plyViewed], true);
      setFen(fenHistory[plyViewed]);
      setSelectedIndex(plyViewed);
    }
  };

  const nextMove = () => {
    if (plyViewed < fenHistory.length - 1) {
      plyViewed++;
      board.current.setPosition(fenHistory[plyViewed]);
      setFen(fenHistory[plyViewed]);
      setSelectedIndex(plyViewed);
    }
  };

  const lastMove = () => {
    // console.log('last move gameDetails', gameDetails);
    if (plyViewed < fenHistory.length - 1) {
      plyViewed = fenHistory.length - 1;
      board.current.setPosition(fenHistory[plyViewed]);
      setFen(fenHistory[plyViewed]);
      setSelectedIndex(plyViewed);
    }
  };

  // console.log('chess in App.js', chess);

  // useEffect(() => {
  const boardOrientationSwitch = () => {
    board.current.setOrientation(
      board.current.getOrientation() === 'w' ? 'b' : 'w'
    );
    boardOrientation = board.current.getOrientation();

    if (switchState.checkedA) {
      gameDetails.currentPositionPieceValues =
        gameDetails.currentPositionPieceValues
          .map(row => row.reverse())
          .reverse();

      showPieceValues(gameDetails, boardOrientation, chess, fenHistory);
    }
  };
  // ,[]}

  const passAcrossSelectedGame = props => {
    // Set pgn to that passed by Games pageSize
    if (props.passedGamesFlag === 1) {
      // alert(props.passedGamesFlag);
      // if (passedGamesFlag === 1) {
      // passedGamesFlag = 0;
      // alert('yo');
      console.log('games pgn', props.selectedGamesPGN);

      props.setPassedGamesFlag(0);

      // newGame();
      gameDetails = {
        blackKingSquare: '',
        whiteKingSquare: '',
        unopposedBishopSquareColour: '',
        unopposedBishopSquare: '',
        centralPawnMajority: '',
        openFiles: [],
        whiteSemiOpenFiles: [],
        blackSemiOpenFiles: [],
        whiteKnightOutpostSquares: [],
        blackKnightOutpostSquares: [],
        whitePassedPawns: [],
        blackPassedPawns: [],
        whiteIsolatedPawns: [],
        blackIsolatedPawns: [],
        whiteDoubledPawns: [],
        blackDoubledPawns: [],
        centralPawnMajorityPawns: [],
        weakSquaresWhite: [],
        weakSquaresBlack: [],
        currentPosition: [[], [], [], [], [], [], [], []],
        whiteAdvantageTags: [],
        blackAdvantageTags: [],
        neutralTags: [],
        totalTags: [],
        currentPositionPieceValues: [[], [], [], [], [], [], [], []],
        pgnWithLines: '',
        gamePgnArray: [],
        whiteAdvisoryTags: [],
        blackAdvisoryTags: [],
        // connectedRooks: [],
      };

      // None standard pgn with lines instead of numbers
      gameDetails.pgnWithLines = pgnExpanded(fen);
      gameDetails.gamePgnArray = addPgnToArray(gameDetails);

      boardOrientation = 'w';
      // currentTag = '';
      // tagColour = '';

      chess.load_pgn(props.selectedGamesPGN);
      // chess.load_pgn(importedPGN);
      plyViewed = 0;

      setHistory(chess.history());

      const auxChess = new Chess();

      // const fenHistory = [];
      fenHistory = [];
      fenHistory.push(auxChess.fen());
      chess.history().forEach(move => {
        auxChess.move(move);
        fenHistory.push(auxChess.fen());
      });
      board.current.setPosition(fenHistory[plyViewed]);
      // setFen(fenHistory[plyViewed]);
      // setSelectedIndex(plyViewed);
    }
  };

  // passAcrossSelectedGame(props);

  // useEffect(() => {
  //   // console.log('my props', props);
  //   passAcrossSelectedGame(props);
  //   // firstMove();
  // }, [props]);

  // useEffect(() => {
  //   console.log('plyViewed', plyViewed);
  //   console.log('currentplyViewed', props.currentPlyViewed);
  //   if (props.currentPlyViewed > 0) {
  //     plyViewed = props.currentPlyViewed;
  //     setFen(fenHistory[plyViewed]);
  //     setSelectedIndex(plyViewed);
  //     // board.current.setPosition(fenHistory[plyViewed]);
  //     board.current.setPosition(fenHistory[plyViewed], false);
  //   }
  // }, [props]);

  // const boardOrientationSwitch = () => {
  //   board.current.setOrientation(
  //     board.current.getOrientation() === 'w' ? 'b' : 'w'
  //   );
  //   boardOrientation = board.current.getOrientation();

  //   if (switchState.checkedA) {
  //     gameDetails.currentPositionPieceValues =
  //       gameDetails.currentPositionPieceValues
  //         .map(row => row.reverse())
  //         .reverse();

  //     showPieceValues(gameDetails, boardOrientation, chess, fenHistory);
  //   }
  // };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Grid
        container
        //Main page container
        justify="center"
      >
        <Box mt={0} mb={5} width={1200}>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={3}>
              <Paper
                className={classes.paper}
                style={{ maxHeight: 600, overflow: 'auto' }}
              >
                <Typography variant="h6" component="h6">
                  Moves
                </Typography>

                {/* {moveHistory()} */}
                <MoveList
                  chess={chess}
                  board={board}
                  fenHistory={fenHistory}
                  plyViewed={plyViewed}
                  classes={classes}
                  selectedIndex={selectedIndex}
                  handleListItemClick={handleListItemClick}
                  setFen={setFen}
                  setSelectedIndex={setSelectedIndex}
                  changePlyViewedValue={changePlyViewedValue}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper id="board"></Paper>
            </Grid>
            <Grid
              item
              xs={3}
              // Right hand of board pane
            >
              {/* <Paper></Paper> */}
              <Paper
                className={classes.paperTagsSection}
                style={{ maxHeight: 592, overflow: 'auto' }}
              >
                <Box>
                  {/* <AppBar position="static"> */}
                  <Tabs
                    value={tagScoreTabsPanelValue}
                    indicatorColor="primary"
                    textColor="primary"
                    // variant="default"
                    onChange={handleTagScoreTabsPanel}
                    // aria-label="disabled tabs example"
                  >
                    <Tab label="Advisor" style={{ minWidth: '50%' }} />
                    <Tab label="Drills" style={{ minWidth: '50%' }} />
                  </Tabs>
                  <AdvisorPanel
                    advisorTags={gameDetails.whiteAdvantageTags}
                    popupInstruction={popupInstruction}
                    highlightSquares={highlightSquares}
                    board={board}
                    category="WhiteAdvantages"
                    // className={classes.paper}
                    classes={classes}
                    gameDetails={gameDetails}
                    tagScoreTabsPanelValue={tagScoreTabsPanelValue}
                  />

                  {/* {tagScoreTabsPanelValue === 1 && myComponent} */}

                  {/* </AppBar> */}
                </Box>
              </Paper>
            </Grid>
            {/* </Grid> */}
            <Grid item xs={3}>
              {/* <Paper className={classes.paper}></Paper> */}
              <Button
                variant="outlined"
                color="primary"
                size="large"
                // style={{ maxWidth: '108px', minWidth: '108px' }}
                onClick={() => {
                  newGame();
                }}
              >
                New Game
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                variant="outlined"
                color="primary"
                size="large"
                // style={{ maxWidth: '108px', minWidth: '108px' }}
                onClick={() => {
                  // showPieceValues(
                  //   gameDetails,
                  //   boardOrientation,
                  //   chess,
                  //   fenHistory
                  // );
                  let path = `games`;
                  pageHistory.push(path);
                }}
              >
                OPEN GAME
              </Button>
              <Dialog
                open={openImportPGN}
                // onClose={handleCloseImportPGN}
                aria-labelledby="form-dialog-title"
                fullWidth={true}

                // maxWidth={'md'}
              >
                <DialogTitle id="form-dialog-title">Import PGN</DialogTitle>
                <DialogContent styles>
                  {/* <DialogContentText>
              Past in your PGN text below and click Submit.
            </DialogContentText> */}
                  <TextField
                    autoFocus
                    // margin={dense}
                    id="name"
                    label="Past your PGN text here"
                    type="email"
                    fullWidth
                    multiline
                    rows={10}
                    // minWidth="300px"
                    onChange={event => {
                      setImportedPGN(event.target.value);
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseImportPGN} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleCloseImportPGN} color="primary">
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                id="myDialog"
                classes={{ paper: classes.dialog }}
                //Modal dialog for advice on position
                onClose={handleCloseTagInstructions}
                open={openTagInstructions}
                // onClose={handleCloseImportPGN}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                // maxWidth={'md'}
              >
                {/* <DialogTitle id="helper-dialog-title">
            {currentTag}
          </DialogTitle> */}

                {/* <DialogContent dividers> */}
                {/* </DialogContent> */}
                <DialogActions>
                  {/* <Button
              onClick={handleCloseTagInstructions}
              color="primary"
              >
              Close
            </Button> */}
                  <IconButton
                    className={classes.customizedButton}
                    onClick={handleCloseTagInstructions}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogActions>
                <TagContent
                  tagText={currentTag}
                  tagColour={tagColour}
                  fen={fen}
                  plyViewed={plyViewed}
                />
              </Dialog>
            </Grid>

            <MoveButtons
              //TODO:
              chess={chess}
              board={board}
              fenHistory={fenHistory}
              plyViewed={plyViewed}
              classes={classes}
              selectedIndex={selectedIndex}
              handleListItemClick={handleListItemClick}
              setFen={setFen}
              setSelectedIndex={setSelectedIndex}
              switchState={switchState}
              handleChangeSwitches={handleChangeSwitches}
              firstMove={firstMove}
              previousMove={previousMove}
              nextMove={nextMove}
              lastMove={lastMove}
              buttonWidth={buttonWidth}
              boardOrientationSwitch={boardOrientationSwitch}
            />
            <Grid item xs={3}>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                // style={{ maxWidth: '108px', minWidth: '108px' }}
                onClick={() => {
                  handleClickImportPGN();
                }}
              >
                Export PGN
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                variant="outlined"
                color="primary"
                size="large"
                // style={{ maxWidth: '108px', minWidth: '108px' }}
                onClick={() => {
                  handleClickImportPGN();
                }}
              >
                Import PGN
              </Button>
              {/* <Paper id="board3">test</Paper> */}
              {/* <Paper className={classes.paper}></Paper> */}
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Footer />
    </ThemeProvider>
  );
};

export default AnalyseGame;
