import {
  // checkOpeningPawnStructurePatterns,
  // checkKingSafetyPawnStructurePatterns,
  // checkMiscPawnStructurePatterns,
  // checkKnightOutpostPatterns,
  // checkFilesAndPawns,
  // findUnopposedBishopColour,
  findUnopposedBishopSquare,
  // checkTwoBishops,
  // checkCentralPawnMajority,
  // checkDevelopment,
  // checkUncastledKing,
  // checkWeakSquaresBlack,
  // checkWeakSquaresWhite,
} from './patternEngine';

import {
  findSquareColour,
  findPieces,
  // convertColumnsToLetters,
  // retrievePiece,
  // checkPattern,
  // columnStringsArray,
  // columnToNumber,
  // checkPieceInColumn,
  // checkPieceInColumnArray,
  // checkPieceMobilityCount,
} from './utilities';

// Highlighting squares on hover *********
export const highlightSquares = (name1, category, gameDetails, board) => {
  if (name1 === "Black's King is uncastled") {
    board.current.addMarker('e8', {
      slice: 'marker3',
    });
  }
  if (name1 === "White's King is uncastled") {
    board.current.addMarker('e1', {
      slice: 'marker3',
    });
  }
  if (
    name1.includes('Semi-open files:') &
    (category === 'WhiteAdvantages') &
    gameDetails.whiteAdvantageTags.join().includes('Semi-open files')
  ) {
    highlightColumn(gameDetails.whiteSemiOpenFiles, board);
  }
  if (
    name1.includes('Semi-open files:') &
    (category === 'BlackAdvantages') &
    gameDetails.blackAdvantageTags.join().includes('Semi-open files')
  ) {
    highlightColumn(gameDetails.blackSemiOpenFiles, board);
  }
  if (name1.includes('Open files:')) {
    highlightColumn(gameDetails.openFiles, board);
  }
  if (name1.includes('Back rank mate risk for White')) {
    highlightRow([1], board);
  }
  if (name1.includes('Back rank mate risk for Black')) {
    highlightRow([8], board);
  }
  if (name1.includes('Protected Outpost') & (category === 'WhiteAdvantages')) {
    highlightSquaresArray(gameDetails.whiteKnightOutpostSquares, board);
  }
  if (name1.includes('Protected Outpost') & (category === 'BlackAdvantages')) {
    highlightSquaresArray(gameDetails.blackKnightOutpostSquares, board);
  }
  // if (name1.includes('White has the two Bishops')) {
  if (
    name1.includes('The Two Bishops') &
    gameDetails.whiteAdvantageTags.includes('The Two Bishops')
  ) {
    highlightSquaresArray(findPieces('B', gameDetails.currentPosition), board);
  }
  // if (name1.includes('Black has the two Bishops')) {
  if (
    name1.includes('The Two Bishops') &
    gameDetails.blackAdvantageTags.includes('The Two Bishops')
  ) {
    highlightSquaresArray(findPieces('b', gameDetails.currentPosition), board);
  }
  // Unopposed Bishop for White
  if (
    name1.includes(`Unopposed`) &
    (gameDetails.whiteAdvantageTags.includes('Unopposed dark-squared Bishop') ||
      gameDetails.whiteAdvantageTags.includes('Unopposed light-squared Bishop'))
  ) {
    board.current.addMarker(
      findUnopposedBishopSquare(
        'White',
        findPieces,
        findSquareColour,
        gameDetails.unopposedBishopSquareColour,
        gameDetails.unopposedBishopSquare,
        gameDetails.currentPosition
      ),
      {
        slice: 'marker3',
      }
    );
  }
  // Unopposed Bishop for Black

  if (
    name1.includes(`Unopposed`) &
    (gameDetails.blackAdvantageTags.includes('Unopposed dark-squared Bishop') ||
      gameDetails.blackAdvantageTags.includes('Unopposed light-squared Bishop'))
  ) {
    board.current.addMarker(
      findUnopposedBishopSquare(
        'Black',
        findPieces,
        findSquareColour,
        gameDetails.unopposedBishopSquareColour,
        gameDetails.unopposedBishopSquare,
        gameDetails.currentPosition
      ),
      {
        slice: 'marker3',
      }
    );
  }
  if (name1.includes('Passed pawns') & (category === 'WhiteAdvantages')) {
    highlightSquaresArray(gameDetails.whitePassedPawns, board);
  }
  if (name1.includes('Passed pawns') & (category === 'BlackAdvantages')) {
    highlightSquaresArray(gameDetails.blackPassedPawns, board);
  }
  if (name1.includes('Isolated pawns') & (category === 'BlackAdvantages')) {
    highlightSquaresArray(gameDetails.whiteIsolatedPawns, board);
  }
  if (name1.includes('Isolated pawns') & (category === 'WhiteAdvantages')) {
    highlightSquaresArray(gameDetails.blackIsolatedPawns, board);
  }
  if (name1.includes('Doubled pawns') & (category === 'BlackAdvantages')) {
    highlightSquaresArray(gameDetails.whiteDoubledPawns, board);
  }
  if (name1.includes('Doubled pawns') & (category === 'WhiteAdvantages')) {
    highlightSquaresArray(gameDetails.blackDoubledPawns, board);
    // alert(blackDoubledPawns);
  }
  //TODO:
  if (
    name1.includes('Central Pawn Majority') &
    (category === 'WhiteAdvantages')
  ) {
    highlightSquaresArray(gameDetails.centralPawnMajorityPawns, board);
  }
  if (
    name1.includes('Central Pawn Majority') &
    (category === 'BlackAdvantages')
  ) {
    highlightSquaresArray(gameDetails.centralPawnMajorityPawns, board);
  }
  if (
    name1.includes('Black has Weak Squares') &
    (category === 'WhiteAdvantages')
  ) {
    highlightSquaresArray(gameDetails.weakSquaresBlack, board);
  }
  if (
    name1.includes('White has Weak Squares') &
    (category === 'BlackAdvantages')
  ) {
    highlightSquaresArray(gameDetails.weakSquaresWhite, board);
  }

  if (name1.includes('Worst Placed Piece') & (category === 'WhiteAdvisory')) {
    highlightSquaresArray(name1.slice(-2).split(), board);
  }
  if (name1.includes('Worst Placed Piece') & (category === 'BlackAdvisory')) {
    highlightSquaresArray(name1.slice(-2).split(), board);
  }

  if (name1.includes('Dominate the Knight') & (category === 'WhiteAdvisory')) {
    highlightSquaresArray(name1.slice(-2).split(), board);
  }

  if (name1.includes('Dominate the Knight') & (category === 'BlackAdvisory')) {
    highlightSquaresArray(name1.slice(-2).split(), board);
  }
};
// ***************************************

// Highlight a column. Input column names e.g. e,h
const highlightColumn = (columns, board) => {
  columns.forEach(element => {
    for (let i = 1; i <= 8; i++) {
      board.current.addMarker(element + i, {
        // class: 'emphasize',
        slice: 'marker3',
      });
    }
  });
};

// Highlight a row. Input row names e.g. 3,5
const highlightRow = (rows, board) => {
  rows.forEach(row => {
    // for (let i = 1; i <= 8; i++) {
    [...'abcdefgh'].map(column => {
      board.current.addMarker(column + row, {
        // class: 'emphasize',
        slice: 'marker3',
      });
    });
  });
};

// Highlight array of squares
const highlightSquaresArray = (squaresArray1, board) => {
  // alert(squaresArray1);
  squaresArray1.forEach(element => {
    board.current.addMarker(element, {
      // class: 'emphasize',
      slice: 'marker3',
    });
  });
};
