import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { makeStyles } from '@material-ui/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

// import logo from '../../assets/logo - flat - v6.png';
// import logo from '../../assets/logo - flat - chessdashboard-v1.png';
import logo from '../../assets/logo - flat - v7.png';
// import logo from '../../assets/logov1.svg';

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const useStyles = makeStyles(theme => ({
  toolbarMargin: { ...theme.mixins.toolbar, marginBottom: '0em' },
  logo: { height: '4em' },
  logoContainer: {
    padding: 10,
    '&:hover': { backgroundColor: 'transparent' },
  },
  tabContainer: { marginLeft: 'auto' },
  tab: {
    ...theme.typography.tab,
    minWidth: 10,
    marginLeft: '15px',
    marginRight: '40px',
  },
  button: {
    ...theme.typography.estimate,
    borderRadius: '50px',
    marginLeft: '50px',
    marginRight: '25px',
    height: '45px',
  },
  menu: {
    // backgroundColor: theme.palette.common.blue,
    backgroundColor: theme.palette.common.white,
    color: 'white',

    borderRadius: '0px',
  },
  menuItem: {
    ...theme.typography.tab,
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleChange = (e, value) => {
    setValue(value);
  };

  // const handleClick = e => {
  //   setAnchorEl(e.currentTarget);
  //   setOpen(true);
  // };

  const handleMenuItemClick = (e, i) => {
    setAnchorEl(null);
    setOpen(false);
    setSelectedIndex(i);
  };

  const handleClose = e => {
    setAnchorEl(null);
    setOpen(false);
  };

  const menuOptions = [
    { name: 'Free Tools', link: '/services' },
    { name: 'Premium Tools', link: 'customsoftware' },
    { name: 'Mobile App Development', link: '/mobileapps' },
    { name: 'Website Development', link: '/websites' },
  ];

  useEffect(() => {
    if (window.location.pathname === '/') {
      // if (window.location.pathname === '/' && value !== 9) {
      setValue(false);
    } else if (window.location.pathname === '/features' && value !== 0) {
      setValue(0);
    } else if (window.location.pathname === '/pricing' && value !== 1) {
      setValue(1);
    } else if (window.location.pathname === '/about' && value !== 2) {
      setValue(2);
    } else if (window.location.pathname === '/analyze' && value !== 3) {
      setValue(3);
    }

    switch (window.location.pathname) {
      case '/':
        if (value !== false) {
          setValue(false);
          // setSelectedIndex(9);
        }
        break;
      case '/features':
        if (value !== 0) {
          setValue(0);
          // setSelectedIndex(0);
        }
        break;
      case '/pricing':
        if (value !== 1) {
          setValue(1);
          // setSelectedIndex(1);
        }
        break;
      case '/about':
        if (value !== 2) {
          setValue(2);
          // setSelectedIndex(2);
        }
        break;
      // case '/analyze':
      //   if (value !== 3) {
      //     setValue(3);
      //     // setSelectedIndex(3);
      //   }
      //   break;

      default:
        setValue(false);
        // setSelectedIndex(false);
        return false;
    }
  }, [value]);

  return (
    <React.Fragment>
      <ElevationScroll>
        <AppBar position="fixed">
          <Grid container justify="center">
            <Box width={1230}>
              <Grid container>
                <Grid item xs={8}>
                  <Toolbar disableGutters>
                    <Button
                      component={Link}
                      to="/"
                      disableRipple
                      onClick={() => setValue(false)}
                      className={classes.logoContainer}
                    >
                      <img
                        alt="company logo"
                        className={classes.logo}
                        src={logo}
                      />
                    </Button>

                    <Tabs
                      value={value}
                      // value="2"
                      onChange={handleChange}
                      className={classes.tabContainer}
                      indicatorColor="primary"
                    >
                      {/* <Tab
                      className={classes.tab}
                      component={Link}
                      to="/"
                      label="Home"
                    /> */}
                      <Tab
                        className={classes.tab}
                        component={Link}
                        to="/features"
                        label="Features"
                      />
                      <Tab
                        className={classes.tab}
                        component={Link}
                        to="/pricing"
                        label="Pricing"
                      />
                      <Tab
                        className={classes.tab}
                        component={Link}
                        to="/about"
                        label="About"
                      />
                      {/* <Tab
                        className={classes.tab}
                        component={Link}
                        to="/analyze"
                        label="Analyze"
                      /> */}
                    </Tabs>
                  </Toolbar>
                </Grid>
                {/* <Grid container> */}
                <Grid
                  item
                  style={{ display: 'flex', alignItems: 'center' }}
                  // style={{ display: 'flex', alignItems: 'flex-start' }}
                  xs={4}
                >
                  {/* <Toolbar disableGutters> */}
                  <Tabs className={classes.tabContainer}>
                    <Tab
                      className={classes.tab}
                      component={Link}
                      to="/analyze"
                      label="Login"
                    />
                  </Tabs>
                  {/* <Typography className={classes.root}>
                    <Link href="#" onClick={menuOptions}>
                      Link
                    </Link>
                  </Typography> */}
                  <Button
                    variant="contained"
                    color="default"
                    size="medium"
                    // style={{ marginTop: '1.1em' }}
                    // style={{ maxWidth: '108px', minWidth: '108px' }}
                    onClick={() => {
                      // handleClickImportPGN();
                    }}
                  >
                    Try for Free
                  </Button>
                  {/* </Toolbar> */}
                </Grid>

                <Grid></Grid>
                {/* </Grid> */}
              </Grid>
            </Box>
          </Grid>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarMargin} />
    </React.Fragment>
  );
}
