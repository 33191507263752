import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.common.blue,
    width: '100%',
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Grid
        container
        justify="center"
        style={{
          height: '100px',
          color: 'white',
          fontSize: '12pt',
          backgroundColor: '#3a7ab9',
          padding: '40px',
        }}
      >
        © 2021 ChessDashboard. All rights reserved.
      </Grid>
    </footer>
  );
}
