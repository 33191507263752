import Chess from 'chess.js';

// **** Function list ****
// findSquareColour = square
// findPieces = (piece, currentPosition)
// convertColumnsToLetters = column
// retrievePiece = (coords, currentPosition)
// checkPattern = (pattern, currentPosition)
// columnStringsArray = currentPosition
// columnToNumber = column
// checkPieceInColumn = (piece, column, currentPosition)
// checkPieceInColumnArray = (piece, column, currentPosition)
// checkGamePhase = currentPosition
// checkDevelopmentByColour = (colour, currentPosition)
// checkPieceMobilityCount = (coords, chess, currentPiece)
// checkPawnValueBonus = (
//   coords,
//   whiteIsolatedPawns,
//   blackIsolatedPawns,
//   whiteDoubledPawns,
//   blackDoubledPawns,
//   currentPiece
// )
// checkKnightOutpostBonus = (coords, knightOutpostSquares)
// pgnExpanded = fen
// addPgnToArray(gameDetails)
// reverseString(str)
// countCharacterInString = (character, inputString)
// roundNumber = (number, decimalPlaces)
// checkPieceInRowArray = (piece, row, currentPosition)
// **** ---- *****/

export const findSquareColour = square => {
  let column = square.charAt(0);
  let row = square.charAt(1) - 1;
  let columnToNumber = 0;
  // console.log('column', column, 'row', row);

  switch (column) {
    case 'a':
      columnToNumber = 0;
      break;
    case 'b':
      columnToNumber = 1;
      break;
    case 'c':
      columnToNumber = 2;
      break;
    case 'd':
      columnToNumber = 3;
      break;
    case 'e':
      columnToNumber = 4;
      break;
    case 'f':
      columnToNumber = 5;
      break;
    case 'g':
      columnToNumber = 6;
      break;
    case 'h':
      // return pgnExpanded.charAt(7 + (8 - row) * 8);
      columnToNumber = 7;
      break;
    default:
  }

  if ((row + columnToNumber) % 2 === 0) {
    // console.log('square', square);
    return 'dark';
  } else {
    // console.log('square', square);
    return 'light';
  }
};

export const findPieces = (piece, currentPosition) => {
  //pieceTable
  //Build array of pieces based on piece input e.g. B
  let pieceTable = [];
  // console.log('currentPosition', currentPosition);

  for (let column = 0; column <= 7; column++) {
    for (let row = 7; row >= 0; row--) {
      if (currentPosition[row][column] === piece) {
        pieceTable.push(convertColumnsToLetters(column) + (row + 1));
      }
    }
  }
  return pieceTable;
};

export const convertColumnsToLetters = column => {
  switch (column) {
    case 0:
      return 'a';
    case 1:
      return 'b';
    case 2:
      return 'c';
    case 3:
      return 'd';
    case 4:
      return 'e';
    case 5:
      return 'f';
    case 6:
      return 'g';
    case 7:
      return 'h';
    default:
  }
};

export const retrievePiece = (coords, currentPosition) => {
  let column;
  let row;

  column = coords.charAt(0);
  row = parseInt(coords.charAt(1)) - 1;

  // console.log('col:', column, ', row:', row, ', coords', coords);

  switch (column) {
    case 'a':
      return currentPosition[row][0];
    case 'b':
      return currentPosition[row][1];
    case 'c':
      return currentPosition[row][2];
    case 'd':
      return currentPosition[row][3];
    case 'e':
      return currentPosition[row][4];
    case 'f':
      return currentPosition[row][5];
    case 'g':
      return currentPosition[row][6];
    case 'h':
      return currentPosition[row][7];
    default:
  }
};

export const checkPattern = (pattern, currentPosition) => {
  let patternExists = true;
  let currentPiece;
  let currentSquare;
  let patternArray = [];

  patternArray = pattern.split(' ');

  for (
    let patternElement = 0;
    patternElement < patternArray.length;
    patternElement++
  ) {
    currentPiece = patternArray[patternElement].substring(0, 1);
    // currentSquare = patternElement.charAt(1) & patternElement.charAt(2);
    currentSquare = patternArray[patternElement].substring(1, 3);

    if (
      currentPiece === '-' &&
      // patternExists === true &&
      (retrievePiece(currentSquare, currentPosition) === 'p' ||
        retrievePiece(currentSquare, currentPosition) === 'P')
    ) {
      patternExists = false;
      break;
      // console.log('this got triggered');
    } else if (
      currentPiece === '=' &&
      retrievePiece(currentSquare, currentPosition) === '-'
      // &&        patternExists === true
    ) {
      // patternExists = true;
    } else if (
      currentPiece === '-' &&
      retrievePiece(currentSquare, currentPosition) !== 'p' &&
      retrievePiece(currentSquare, currentPosition) !== 'P'
      // &&        patternExists === true
    ) {
      // patternExists = true;
    } else if (
      currentPiece !== '-' &&
      currentPiece === retrievePiece(currentSquare, currentPosition)
      //  &&        patternExists === true
    ) {
      // patternExists = true;
    } else {
      // console.log(
      //   'Endpoint triggered. Current piece:',
      //   currentPiece,
      //   'Current square:',
      //   retrievePiece(currentSquare)
      // );
      patternExists = false;
      break;
    }
  }
  // console.log('function returns', patternExists);
  return patternExists;
  // alert(patternExists);
};

// addPgnToColumnStringArray - turn array of pieces into array of string columns
export const columnStringsArray = currentPosition => {
  // let column;
  // let row;
  let columnStringArrayTmp = [];
  let buildColumnString = '';

  for (let column = 0; column <= 7; column++) {
    for (let row = 0; row <= 7; row++) {
      buildColumnString = buildColumnString + currentPosition[row][column];
    }

    columnStringArrayTmp.push(buildColumnString);
    buildColumnString = '';
  }

  return columnStringArrayTmp;
};

export const columnToNumber = column => {
  switch (column) {
    case 'a':
      return 0;
    case 'b':
      return 1;
    case 'c':
      return 2;
    case 'd':
      return 3;
    case 'e':
      return 4;
    case 'f':
      return 5;
    case 'g':
      return 6;
    case 'h':
      return 7;
    default:
  }
};

export const checkPieceInColumn = (piece, column, currentPosition) => {
  if (
    columnStringsArray(currentPosition)[columnToNumber(column)].includes(piece)
  ) {
    // alert(
    //   columnStringsArray(currentPosition)[columnToNumber(column)].includes(
    //     piece
    //   )
    // );
    return true;
  } else {
    return false;
  }
};

export const checkPieceInColumnArray = (piece, column, currentPosition) => {
  // Return array of coordinates for piece
  let pieceTable = [];
  let columnNumber = columnToNumber(column);

  // for (let column = 0; column <= 7; column++) {
  for (let row = 7; row >= 0; row--) {
    if (currentPosition[row][columnNumber] === piece) {
      pieceTable.push(convertColumnsToLetters(columnNumber) + (row + 1));
    }
  }

  if (pieceTable.length > 0) {
    return pieceTable;
  } else return [];
};

export const checkGamePhase = currentPosition => {
  //In progress: opening, middlegame or endgame
  let count = 0;

  for (let column = 0; column <= 7; column++) {
    for (let row = 7; row >= 0; row--) {
      if ((currentPosition[row][column] === 'P') | 'p') count++;
    }
  }
  return count;
};

export const checkDevelopmentByColour = (colour, currentPosition) => {
  //Return value out of 7 for how developed they are
  let row;
  let howDeveloped = 7;
  let pos = '';

  if (colour === 'white') {
    howDeveloped = 7;
    row = 0;
    for (let column = 7; column >= 0; column--) {
      // console.log(currentPosition[row][column]);
      pos = currentPosition[row][column];
      if (pos === 'R' || pos === 'N' || pos === 'B' || pos === 'Q') {
        // if (pos === ('R' || 'N' || 'B' || 'Q')) {
        howDeveloped--;
      }
    }
    // console.log('howdeveloperedWhite', howDeveloped);
    // return howDeveloped;
  }

  if (colour === 'black') {
    howDeveloped = 7;
    row = 7;
    for (let column = 7; column >= 0; column--) {
      pos = currentPosition[row][column];
      if (pos === 'r' || pos === 'n' || pos === 'b' || pos === 'q') {
        howDeveloped--;
      }
    }
    // console.log('howdeveloperedBlack', howDeveloped);
  }
  return howDeveloped;
  // console.log('how developed', howDeveloped);
};

export const checkPieceMobilityCount = (coords, chess, currentPiece) => {
  let legalMoves = [];
  let legalMovesCount;
  let fenTmp;
  let fenTmp2;

  let chessTmp = new Chess();

  fenTmp = chess.fen();

  if (['P', 'N', 'B', 'R', 'Q', 'K'].includes(currentPiece)) {
    fenTmp2 = fenTmp.replace(' b ', ' w ');
    // console.log('Its a white piece so set move colour on fen to w');
  } else if (['p', 'n', 'b', 'r', 'q', 'k'].includes(currentPiece)) {
    fenTmp2 = fenTmp.replace(' w ', ' b ');
    // console.log('Its a black piece so set move colour on fen to b');
  }

  // console.log('currentPiece:', currentPiece, 'chess fen2:', fenTmp2);

  fenTmp2 = fenTmp2.replace(
    /a3|b3|c3|d3|f3|g3|h3|a6|b6|c6|d6|e6|f6|g6|h6/g,
    '-'
  );

  chessTmp.load(fenTmp2);

  legalMoves = chessTmp.moves({ square: coords });

  // console.log('legalMoves', legalMoves);
  // console.log('coords', coords);
  legalMovesCount = legalMoves.length / 6 - 0.4;
  // console.log(
  //   'legalMovesCount',
  //   legalMovesCount,
  //   'legalMoves.length',
  //   legalMoves.length
  // );
  return legalMovesCount;
};

export const checkPawnValueBonus = (
  coords,
  whiteIsolatedPawns,
  blackIsolatedPawns,
  whiteDoubledPawns,
  blackDoubledPawns,
  currentPiece
) => {
  let bonus = 0;

  if (
    whiteIsolatedPawns.length > 0 &&
    whiteIsolatedPawns.includes(coords) &&
    currentPiece === 'P'
  ) {
    bonus = -1;
  } else if (
    blackIsolatedPawns.length > 0 &&
    blackIsolatedPawns.includes(coords) &&
    currentPiece === 'p'
  ) {
    bonus = -1;
  }

  if (
    whiteDoubledPawns.length > 0 &&
    whiteDoubledPawns.includes(coords) &&
    currentPiece === 'P'
  ) {
    bonus = -1;
  } else if (
    blackDoubledPawns.length > 0 &&
    blackDoubledPawns.includes(coords) &&
    currentPiece === 'p'
  ) {
    bonus = -1;
  }

  return bonus;
};

export const checkKnightOutpostBonus = (coords, knightOutpostSquares) => {
  let bonus;

  // console.log('knightOutpostSquares', knightOutpostSquares);

  if (
    knightOutpostSquares.length > 0 &&
    knightOutpostSquares.includes(coords)
  ) {
    bonus = 2;
  } else {
    bonus = 0;
  }

  return bonus;
};

// PGN utilities

export const pgnExpanded = fen => {
  // let pgn1 = 'r4r1k/p1P1qppp/1p6/8/5B2/2Q1P3/P4PPP/R3KB1R b KQ - 0 21'
  // console.log('Fen error', fen);
  let pgn1 = fen
    .replace(/\//g, '')
    .replaceAll('1', '-')
    .replaceAll('2', '--')
    .replaceAll('3', '---')
    .replaceAll('4', '----')
    .replaceAll('5', '-----')
    .replaceAll('6', '------')
    .replaceAll('7', '-------')
    .replaceAll('8', '--------');

  return pgn1;
};

export function addPgnToArray(gameDetails) {
  for (let column = 0; column <= 7; column++) {
    for (let row = 7; row >= 0; row--) {
      gameDetails.currentPosition[row][column] =
        gameDetails.pgnWithLines.charAt(column + (7 - row) * 8);
    }
  }
}

export function reverseString(str) {
  return str.split('').reverse().join('');
}

// export const Gamedetails

export const countCharacterInString = (character, inputString) => {
  // alert(character, myString);
  // console.log('character', character);
  // console.log('myString', myString);

  // let regex = new RegExp(character, 'g');
  // return string.match(regex || []).length;
  // return myString.match(regex || []).length;

  return inputString.split('-').length - 1;
};

export const roundNumber = (number, decimalPlaces) => {
  const factorOfTen = Math.pow(10, decimalPlaces);
  return Math.round(number * factorOfTen) / factorOfTen;
};

export const checkPieceInRowArray = (piece, row, currentPosition) => {
  // Return array of coordinates for piece
  let pieceTable = [];
  // let columnNumber = columnToNumber(column);

  // for (let column = 0; column <= 7; column++) {
  for (let column = 7; column >= 0; column--) {
    if (currentPosition[row][column] === piece) {
      pieceTable.push(convertColumnsToLetters(column) + (row + 1));
    }
  }

  if (pieceTable.length > 0) {
    return pieceTable;
  } else return [];
};

export const upperCase = string => {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
};
