import React from 'react';
// import { Typography } from '@material-ui/core';
import { UncastledKingWhite, UncastledKingBlack } from './modals/UncastledKing';
import {
  UnopposedBishopWhite,
  UnopposedBishopBlack,
} from './modals/UnopposedBishop';
import { TwoBishopsWhite, TwoBishopsBlack } from './modals/TwoBishops';
import { SemiOpenFilesWhite, SemiOpenFilesBlack } from './modals/SemiOpenFiles';
import { OpenFiles } from './modals/OpenFiles';
import { DoubledPawnsWhite, DoubledPawnsBlack } from './modals/DoubledPawns';
import { IsolatedPawnsWhite, IsolatedPawnsBlack } from './modals/IsolatedPawns';
import {
  ProtectedOutpostsWhite,
  ProtectedOutpostsBlack,
} from './modals/ProtectedOutposts';
import { PassedPawnsWhite, PassedPawnsBlack } from './modals/PassedPawns';
import {
  CentralPawnMajorityWhite,
  CentralPawnMajorityBlack,
} from './modals/CentralPawnMajority';
import {
  BetterDevelopedWhite,
  BetterDevelopedBlack,
} from './modals/BetterDeveloped';
import {
  BackRankMateRiskWhite,
  BackRankMateRiskBlack,
} from './modals/BackRankMateRisk';
import { KingInCenterWhite, KingInCenterBlack } from './modals/KingInCenter';
import {
  WeakenedPawnsByKingWhite,
  WeakenedPawnsByKingBlack,
} from './modals/WeakenedPawnsByKing';
import { LittleCenterWhite, LittleCenterBlack } from './modals/LittleCenter';

import { WeakSquaresWhite, WeakSquaresBlack } from './modals/WeakSquares';

import {
  QueensidePawnMajorityWhite,
  QueensidePawnMajorityBlack,
} from './modals/QueensidePawnMajority';

import {
  CrampedPositionWhite,
  CrampedPositionBlack,
} from './modals/CrampedPosition.js';

export default function TagContent(props) {
  // alert(props.tagText);
  // alert(props.tagColour);

  // Check for uncastled Kings
  if (props.tagText === `White's King is uncastled`) {
    return <UncastledKingWhite tagText={props.tagText} fen={props.fen} />;
  } else if (props.tagText === `Black's King is uncastled`) {
    return <UncastledKingBlack tagText={props.tagText} fen={props.fen} />;
  }

  // Check for unopposed Bishops
  if (
    props.tagText.includes(`Unopposed`) &
    (props.tagColour === 'WhiteAdvantages')
  ) {
    return <UnopposedBishopWhite tagText={props.tagText} fen={props.fen} />;
  } else if (
    props.tagText.includes(`Unopposed`) &
    (props.tagColour === 'BlackAdvantages')
  ) {
    return <UnopposedBishopBlack tagText={props.tagText} fen={props.fen} />;
  }

  // Check for The Two Bishops
  if (
    (props.tagText === `The Two Bishops`) &
    (props.tagColour === 'WhiteAdvantages')
  ) {
    return <TwoBishopsWhite tagText={props.tagText} fen={props.fen} />;
  } else if (
    (props.tagText === `The Two Bishops`) &
    (props.tagColour === 'BlackAdvantages')
  ) {
    return <TwoBishopsBlack tagText={props.tagText} fen={props.fen} />;
  }

  // Check for Semi-Open files
  if (
    props.tagText.includes(`Semi-open`) &
    (props.tagColour === 'WhiteAdvantages')
  ) {
    return <SemiOpenFilesWhite tagText={props.tagText} fen={props.fen} />;
  } else if (
    props.tagText.includes(`Semi-open`) &
    (props.tagColour === 'BlackAdvantages')
  ) {
    return <SemiOpenFilesBlack tagText={props.tagText} fen={props.fen} />;
  }

  // Check for Open files
  if (props.tagText.includes(`Open files`)) {
    return <OpenFiles tagText={props.tagText} fen={props.fen} />;
  }

  // Check for Doubled pawns
  if (
    props.tagText.includes(`Doubled pawns`) &
    (props.tagColour === 'WhiteAdvantages')
  ) {
    return <DoubledPawnsWhite tagText={props.tagText} fen={props.fen} />;
  } else if (
    props.tagText.includes(`Doubled pawns`) &
    (props.tagColour === 'BlackAdvantages')
  ) {
    return <DoubledPawnsBlack tagText={props.tagText} fen={props.fen} />;
  }

  // Check for Isolated pawns
  if (
    props.tagText.includes(`Isolated pawns`) &
    (props.tagColour === 'WhiteAdvantages')
  ) {
    return <IsolatedPawnsWhite tagText={props.tagText} fen={props.fen} />;
  } else if (
    props.tagText.includes(`Isolated pawns`) &
    (props.tagColour === 'BlackAdvantages')
  ) {
    return <IsolatedPawnsBlack tagText={props.tagText} fen={props.fen} />;
  }

  // Check for Protected Outpost
  if (
    props.tagText.includes(`Protected Outpost`) &
    (props.tagColour === 'WhiteAdvantages')
  ) {
    return <ProtectedOutpostsWhite tagText={props.tagText} fen={props.fen} />;
  } else if (
    props.tagText.includes(`Protected Outpost`) &
    (props.tagColour === 'BlackAdvantages')
  ) {
    return <ProtectedOutpostsBlack tagText={props.tagText} fen={props.fen} />;
  }

  // Check for Passed Pawns
  if (
    props.tagText.includes(`Passed pawns`) &
    (props.tagColour === 'WhiteAdvantages')
  ) {
    return <PassedPawnsWhite tagText={props.tagText} fen={props.fen} />;
  } else if (
    props.tagText.includes(`Passed pawns`) &
    (props.tagColour === 'BlackAdvantages')
  ) {
    return <PassedPawnsBlack tagText={props.tagText} fen={props.fen} />;
  }

  // Check for Central Pawn Majority
  if (
    props.tagText.includes(`Central Pawn Majority`) &
    (props.tagColour === 'WhiteAdvantages')
  ) {
    return <CentralPawnMajorityWhite tagText={props.tagText} fen={props.fen} />;
  } else if (
    props.tagText.includes(`Central Pawn Majority`) &
    (props.tagColour === 'BlackAdvantages')
  ) {
    return <CentralPawnMajorityBlack tagText={props.tagText} fen={props.fen} />;
  }

  // Check for Better Developed
  if (
    props.tagText.includes(`better developed`) &
    (props.tagColour === 'WhiteAdvantages')
  ) {
    return <BetterDevelopedWhite tagText={props.tagText} fen={props.fen} />;
  } else if (
    props.tagText.includes(`better developed`) &
    (props.tagColour === 'BlackAdvantages')
  ) {
    return <BetterDevelopedBlack tagText={props.tagText} fen={props.fen} />;
  }

  // Back Rank Mate Risk
  if (
    props.tagText.includes(`Back rank mate risk`) &
    (props.tagColour === 'WhiteAdvantages')
  ) {
    return <BackRankMateRiskBlack tagText={props.tagText} fen={props.fen} />;
  } else if (
    props.tagText.includes(`Back rank mate risk`) &
    (props.tagColour === 'BlackAdvantages')
  ) {
    return <BackRankMateRiskWhite tagText={props.tagText} fen={props.fen} />;
  }

  // King in Center
  if (
    props.tagText.includes(`King in center`) &
    (props.tagColour === 'WhiteAdvantages')
  ) {
    return <KingInCenterBlack tagText={props.tagText} fen={props.fen} />;
  } else if (
    props.tagText.includes(`King in center`) &
    (props.tagColour === 'BlackAdvantages')
  ) {
    return <KingInCenterWhite tagText={props.tagText} fen={props.fen} />;
  }

  // Weakened pawns by King
  // if (
  //   props.tagText.includes(`Weakened pawns by Black King`) &
  //   (props.tagColour === 'White')
  // ) {
  //   return <WeakenedPawnsByKingBlack tagText={props.tagText} fen={props.fen} />;
  // } else if (
  //   props.tagText.includes(`Weakened pawns by White King`) &
  //   (props.tagColour === 'Black')
  // ) {
  //   return <WeakenedPawnsByKingWhite tagText={props.tagText} fen={props.fen} />;
  // }

  // Weakened pawns by King
  if (
    props.tagText.includes(`Weakened pawns by Black King`) &
    (props.tagColour === 'WhiteAdvantages')
  ) {
    return <WeakenedPawnsByKingBlack tagText={props.tagText} fen={props.fen} />;
  } else if (
    props.tagText.includes(`Weakened pawns by White King`) &
    (props.tagColour === 'BlackAdvantages')
  ) {
    return <WeakenedPawnsByKingWhite tagText={props.tagText} fen={props.fen} />;
  }

  // Little center
  if (
    props.tagText.includes(`Little Center`) &
    (props.tagColour === 'WhiteAdvantages')
  ) {
    return <LittleCenterWhite tagText={props.tagText} fen={props.fen} />;
  } else if (
    props.tagText.includes(`Little Center`) &
    (props.tagColour === 'BlackAdvantages')
  ) {
    return <LittleCenterBlack tagText={props.tagText} fen={props.fen} />;
  }

  // Weak Squares
  if (
    props.tagText.includes(`has Weak Squares`) &
    (props.tagColour === 'WhiteAdvantages')
  ) {
    return <WeakSquaresBlack tagText={props.tagText} fen={props.fen} />;
  } else if (
    props.tagText.includes(`has Weak Squares`) &
    (props.tagColour === 'BlackAdvantages')
  ) {
    return <WeakSquaresWhite tagText={props.tagText} fen={props.fen} />;
  }

  // Queenside pawn majority
  if (
    props.tagText.includes(`Queenside pawn majority`) &
    (props.tagColour === 'WhiteAdvantages')
  ) {
    return (
      <QueensidePawnMajorityWhite tagText={props.tagText} fen={props.fen} />
    );
  } else if (
    props.tagText.includes(`Queenside pawn majority`) &
    (props.tagColour === 'BlackAdvantages')
  ) {
    return (
      <QueensidePawnMajorityBlack tagText={props.tagText} fen={props.fen} />
    );
  }

  // Cramped position
  if (
    props.tagText.includes(`Black is cramped`) &
    (props.tagColour === 'WhiteAdvantages')
  ) {
    return <CrampedPositionBlack tagText={props.tagText} fen={props.fen} />;
  } else if (
    props.tagText.includes(`White is cramped`) &
    (props.tagColour === 'BlackAdvantages')
  ) {
    return <CrampedPositionWhite tagText={props.tagText} fen={props.fen} />;
  }
}
