import React from 'react';
import { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import {
  Chessboard,
  // INPUT_EVENT_TYPE,
  // MOVE_INPUT_MODE,
  // COLOR,
} from 'cm-chessboard';

export const QueensidePawnMajorityWhite = props => {
  // {props.tagText}

  useEffect(() => {
    new Chessboard(document.getElementById('board3'), {
      position: props.fen,
      // 'rn2k1r1/ppp1pp1p/3p2p1/5bn1/P7/2N2B2/1PPPPP2/2BNK1RR w Gkq - 4 11',
      // orientation: COLOR.black,
    });
  }, []);

  return (
    <>
      <DialogTitle id="helper-dialog-title">
        <Typography variant="h5">White Queenside Pawn Majority</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">White's Strategy</Typography>
        <Typography paragraph="true">
          Consider exchanging off pieces to steer the game towards an endgame.
          Or, always keep in mind that was more pieces come off the board, the
          bigger the advantage of the Queenside pawn majority, if your opponent
          has castled Kingside. Start mobilising the pawns to create a passed
          pawn as you near the endgame.
        </Typography>
        <Typography variant="h6">Black's Strategy</Typography>
        <Typography paragraph="true">xxxx</Typography>
        <Typography variant="h6">Notes</Typography>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography paragraph="true">
              A Queenside pawn majority is usually an asset to keep in reserve
              for the endgame. Since most castling happens Kingside, more pawns
              than your opponent on the Queenside improves your chances of
              creating a passed pawn that is too far from your opponents King,
              thus increasing your chances of promoting to a Queen.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Paper width="5%" id="board3"></Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export const QueensidePawnMajorityBlack = props => {
  // {props.tagText}

  useEffect(() => {
    new Chessboard(document.getElementById('board3'), {
      position: props.fen,
      // 'rn2k1r1/ppp1pp1p/3p2p1/5bn1/P7/2N2B2/1PPPPP2/2BNK1RR w Gkq - 4 11',
      // orientation: COLOR.black,
    });
  }, []);

  return (
    <>
      <DialogTitle id="helper-dialog-title">
        <Typography variant="h5">Black's Queenside Pawn Majority</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">Black's Strategy</Typography>
        <Typography paragraph="true">
          Consider exchanging off pieces to steer the game towards an endgame.
          Or, always keep in mind that was more pieces come off the board, the
          bigger the advantage of the Queenside pawn majority, if your opponent
          has castled Kingside. Start mobilising the pawns to create a passed
          pawn as you near the endgame.
        </Typography>
        <Typography variant="h6">White's Strategy</Typography>
        <Typography paragraph="true">xxxx</Typography>
        <Typography variant="h6">Notes</Typography>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography paragraph="true">
              A Queenside pawn majority is usually an asset to keep in reserve
              for the endgame. Since most castling happens Kingside, more pawns
              than your opponent on the Queenside improves your chances of
              creating a passed pawn that is too far from your opponents King,
              thus increasing your chances of promoting to a Queen.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Paper width="5%" id="board3"></Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};
