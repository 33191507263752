import React from 'react';
import { useEffect } from 'react';
import { Typography } from '@material-ui/core';
// import { Box } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {
  Chessboard,
  // INPUT_EVENT_TYPE,
  // MOVE_INPUT_MODE,
  // COLOR,
} from 'cm-chessboard';

// import './styles/cm-chessboard.css';

export const OpenFiles = props => {
  // {props.tagText}
  // let colour = props.tagText.includes('dark') ? 'dark' : 'light';

  useEffect(() => {
    new Chessboard(document.getElementById('board3'), {
      position: props.fen,
      // 'rn2k1r1/ppp1pp1p/3p2p1/5bn1/P7/2N2B2/1PPPPP2/2BNK1RR w Gkq - 4 11',
      // orientation: COLOR.black,
    });
  }, []);

  return (
    <>
      <DialogTitle id="helper-dialog-title">
        <Typography variant="h5">Open Files</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">White's Strategy</Typography>
        <Typography paragraph="true">
          Place your Rooks on the open file, which exerts pressure down the file
          into your opponents territory. If your opponent moves their Rook to
          this open file, then oppose it with your own Rook. These files can
          also be used for a "Rook Lift".
        </Typography>
        <Typography variant="h6">Black's Strategy</Typography>
        <Typography paragraph="true">
          Place your Rooks on the open file, which exerts pressure down the file
          into your opponents territory. If your opponent moves their Rook to
          this open file, then oppose it with your own Rook. These files can
          also be used for a "Rook Lift".
        </Typography>
        <Typography variant="h6">Notes</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography paragraph="true">
              An open file is where there are no pawns on that file. Often the
              heavy pieces (Rooks and Queens) get exchanged on these files.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Paper width="5%" id="board3"></Paper>
          </Grid>
        </Grid>
        {/* <Typography paragraph="true">[Diagram]</Typography> */}
        {/* <Box width="50%">
          
        </Box> */}
      </DialogContent>
    </>
  );
};
