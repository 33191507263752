import React from 'react';
import { useEffect } from 'react';
import { Typography } from '@material-ui/core';
// import { Box } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {
  Chessboard,
  // INPUT_EVENT_TYPE,
  // MOVE_INPUT_MODE,
  // COLOR,
} from 'cm-chessboard';

export const ProtectedOutpostsWhite = props => {
  // {props.tagText}
  // let colour = props.tagText.includes('dark') ? 'dark' : 'light';

  useEffect(() => {
    new Chessboard(document.getElementById('board3'), {
      position: props.fen,
      // 'rn2k1r1/ppp1pp1p/3p2p1/5bn1/P7/2N2B2/1PPPPP2/2BNK1RR w Gkq - 4 11',
      // orientation: COLOR.black,
    });
  }, []);

  return (
    <>
      <DialogTitle id="helper-dialog-title">
        <Typography variant="h5">
          White has Protected Outposts at {props.tagText.substring(19)}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">White's Strategy</Typography>
        <Typography paragraph="true">
          Ideally, put a Knight on the outpost square. Exchange off an opponents
          bishop of the same colour as the outpost square, so it cannot capture
          that Knight. You can also place your other pieces on the outpost
          squares, but Knights are best.
        </Typography>
        <Typography variant="h6">Black's Strategy</Typography>
        <Typography paragraph="true">
          Try to exchange off a Knight on the outpost square. Or threaten with
          enough pieces to force it to vacate that square.
        </Typography>
        <Typography variant="h6">Notes</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography paragraph="true">
              Outposts are squares in the opponents half of the board that are
              protected by a pawn and cannot be attacked by an opponents pawn.
              Generally, the closer the outpost is to the center, the stronger
              it is.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Paper width="5%" id="board3"></Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export const ProtectedOutpostsBlack = props => {
  // {props.tagText}
  // let colour = props.tagText.includes('dark') ? 'dark' : 'light';

  useEffect(() => {
    new Chessboard(document.getElementById('board3'), {
      position: props.fen,
      // 'rn2k1r1/ppp1pp1p/3p2p1/5bn1/P7/2N2B2/1PPPPP2/2BNK1RR w Gkq - 4 11',
      // orientation: COLOR.black,
    });
  }, []);

  return (
    <>
      <DialogTitle id="helper-dialog-title">
        <Typography variant="h5">
          Black has Protected Outposts at {props.tagText.substring(19)}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">White's Strategy</Typography>
        <Typography paragraph="true">
          Try to exchange off a Knight on the outpost square. Or threaten with
          enough pieces to force it to vacate that square.
        </Typography>
        <Typography variant="h6">Black's Strategy</Typography>
        <Typography paragraph="true">
          Ideally, put a Knight on the outpost square. Exchange off an opponents
          bishop of the same colour as the outpost square, so it cannot capture
          that Knight. You can also place your other pieces on the outpost
          squares, but Knights are best.
        </Typography>
        <Typography variant="h6">Notes</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography paragraph="true">
              Outposts are squares in the opponents half of the board that are
              protected by a pawn and cannot be attacked by an opponents pawn.
              Generally, the closer the outpost is to the center, the stronger
              it is.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Paper width="5%" id="board3"></Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};
