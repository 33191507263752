// import React, { useState, useEffect, useRef } from 'react';

import {
  setPieceValuesArray,
  buildPieceValuesHTML,
  gamePhaseEvaluation,
} from './pieceValuesDisplay';

export const showPieceValues = (
  gameDetails,
  boardOrientation,
  chess,
  fenHistory
) => {
  // // useEffect(() => {
  if (document.getElementsByClassName('piecevalues')[0]) {
    document.getElementsByClassName('piecevalues')[0].remove();
  }
  // // alert(boardOrientation);
  // if (boardOrientation === 'w') {
  //   gameDetails.currentPositionPieceValues = setPieceValuesArray(
  //     gamePhaseEvaluation(gameDetails.currentPosition),
  //     // unopposedBishopSquare,
  //     chess,
  //     fenHistory,
  //     gameDetails
  //   );
  //   // showPieceValues();
  // } else if (boardOrientation === 'b') {
  //   gameDetails.currentPositionPieceValues = setPieceValuesArray(
  //     gamePhaseEvaluation(gameDetails.currentPosition),
  //     // unopposedBishopSquare,
  //     chess,
  //     fenHistory,
  //     gameDetails
  //   )
  //     .map(row => row.reverse())
  //     .reverse();
  // }

  const piecesLocation = document.getElementsByClassName('pieces');

  piecesLocation[0].insertAdjacentHTML(
    'afterend',
    buildPieceValuesHTML(gameDetails.currentPositionPieceValues)
  );
  // });
};

export function removePieceValues() {
  if (document.getElementsByClassName('piecevalues')[0]) {
    document.getElementsByClassName('piecevalues')[0].remove();
  }
}

export const calculatePieceValues = (
  gameDetails,
  boardOrientation,
  chess,
  fenHistory
) => {
  // useEffect(() => {
  // if (document.getElementsByClassName('piecevalues')[0]) {
  //   document.getElementsByClassName('piecevalues')[0].remove();
  // }
  // alert(boardOrientation);

  // console.log('orientation', boardOrientation);
  if (boardOrientation === 'w') {
    gameDetails = setPieceValuesArray(
      gamePhaseEvaluation(gameDetails.currentPosition),
      // unopposedBishopSquare,
      chess,
      fenHistory,
      gameDetails
    );
    // showPieceValues();
  } else if (boardOrientation === 'b') {
    gameDetails = setPieceValuesArray(
      gamePhaseEvaluation(gameDetails.currentPosition),
      // unopposedBishopSquare,
      chess,
      fenHistory,
      gameDetails
    );

    gameDetails.currentPositionPieceValues =
      gameDetails.currentPositionPieceValues
        .map(row => row.reverse())
        .reverse();
  }

  // console.log('zzzzz', gameDetails.currentPositionPieceValues);

  return gameDetails;
};
