import { createMuiTheme } from '@material-ui/core/styles';

const arcBlue = '#1976d2';
// const arcBlue = '#6B9BFF';
// const arcOrange = '#FFBA60';
const arcOrange = '#d25c18';

export default createMuiTheme({
  palette: {
    common: {
      blue: `${arcBlue}`,
      Orange: `${arcOrange}`,
    },
    primary: {
      main: `${arcBlue}`,
    },
    secondary: {
      main: `${arcOrange}`,
    },
    background: {
      paper: 'white',
      default: '#e4f0e2',
    },
  },
  typography: {
    tab: {
      fontFamily: 'Raleway',
      textTransform: 'none',
      fontWeight: 700,
      fontsize: '1rem',
      color: 'white',
    },
  },
});
