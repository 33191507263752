import React from 'react';
import { useEffect } from 'react';
import { Typography } from '@material-ui/core';
// import { Box } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {
  Chessboard,
  // INPUT_EVENT_TYPE,
  // MOVE_INPUT_MODE,
  // COLOR,
} from 'cm-chessboard';

export const TwoBishopsWhite = props => {
  // {props.tagText}
  // let colour = props.tagText.includes('dark') ? 'dark' : 'light';

  useEffect(() => {
    new Chessboard(document.getElementById('board3'), {
      position: props.fen,
      // 'rn2k1r1/ppp1pp1p/3p2p1/5bn1/P7/2N2B2/1PPPPP2/2BNK1RR w Gkq - 4 11',
      // orientation: COLOR.black,
    });
  }, []);

  return (
    <>
      <DialogTitle id="helper-dialog-title">
        <Typography variant="h5">White has the Two Bishops</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">White's Strategy</Typography>
        <Typography paragraph="true">
          Open the position so the two Bishops get maximum power. Put pressure
          on the squares of your unopposed Bishop, where your opponent does not
          have a Bishop on the same square colour.
        </Typography>
        <Typography variant="h6">Black's Strategy</Typography>
        <Typography paragraph="true">
          Try to keep the position closed. This reduces the scope of your
          opponents two Bishops. Put more pawns on the same coloured square as
          your missing Bishop to help strengthen those squares.
        </Typography>
        <Typography variant="h6">Notes</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography paragraph="true">
              The two Bishops is an advantage worth 0.5 pawns, even if the
              position it closed. The advantage is restricted during closed
              positions, but these can open up due to piece exchanges, pawn
              breaks or during the endgame.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Paper width="5%" id="board3"></Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export const TwoBishopsBlack = props => {
  // {props.tagText}
  // let colour = props.tagText.includes('dark') ? 'dark' : 'light';

  useEffect(() => {
    new Chessboard(document.getElementById('board3'), {
      position: props.fen,
      // 'rn2k1r1/ppp1pp1p/3p2p1/5bn1/P7/2N2B2/1PPPPP2/2BNK1RR w Gkq - 4 11',
      // orientation: COLOR.black,
    });
  }, []);

  return (
    <>
      <DialogTitle id="helper-dialog-title">
        <Typography variant="h5">Black has the Two Bishops</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">White's Strategy</Typography>
        <Typography paragraph="true">
          Try to keep the position closed. This reduces the scope of your
          opponents two Bishops. Put more pawns on the same coloured square as
          your missing Bishop to help strengthen those squares.
        </Typography>
        <Typography variant="h6">Black's Strategy</Typography>
        <Typography paragraph="true">
          Open the position so the two Bishops get maximum power. Put pressure
          on the squares of your unopposed Bishop, where your opponent does not
          have a Bishop on the same square colour.
        </Typography>
        <Typography variant="h6">Notes</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography paragraph="true">
              The two Bishops is an advantage worth 0.5 pawns, even if the
              position it closed. The advantage is restricted during closed
              positions, but these can open up due to piece exchanges, pawn
              breaks or during the endgame.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Paper width="5%" id="board3"></Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};
