// import React from 'react';
import {
  mgPawnTableWhite,
  mgPawnTableBlack,
  mgKnightTableWhite,
  mgKnightTableBlack,
  mgBishopTableWhite,
  mgBishopTableBlack,
  mgRookTableWhite,
  mgRookTableBlack,
  mgQueenTableWhite,
  mgQueenTableBlack,
  mgKingTableWhite,
  mgKingTableBlack,
  egPawnTableWhite,
  egPawnTableBlack,
  egKnightTableWhite,
  egKnightTableBlack,
  egBishopTableWhite,
  egBishopTableBlack,
  egRookTableWhite,
  egRookTableBlack,
  egQueenTableWhite,
  egQueenTableBlack,
  egKingTableWhite,
  egKingTableBlack,
} from './pstArrays';

import {
  columnToNumber,
  findSquareColour,
  convertColumnsToLetters,
  checkPieceMobilityCount,
  checkPawnValueBonus,
  checkKnightOutpostBonus,
} from './utilities';

// import Chess from 'chess.js';
// import {
//   IsolatedPawnsBlack,
//   IsolatedPawnsWhite,
// } from '../components/modals/IsolatedPawns';

// Piece Value Calculations

export const pawnValue = coords => {
  let pawnValue = 100;
  let column;
  let row;

  column = columnToNumber(coords.charAt(0));
  row = parseInt(coords.charAt(1)) - 1;

  pawnValue = pawnValue + mgPawnTableWhite[column][row];

  alert(pawnValue);
};

export const setPieceValuesArray = (
  gamePhase,
  // unopposedBishopSquare,
  chess,
  fenHistory,
  gameDetails
  // currentPosition,
  // whiteAdvantageTags,
  // blackAdvantageTags,
  // unopposedBishopSquare,
  // whiteIsolatedPawns,
  // blackIsolatedPawns,
  // whiteKnightOutpostSquares,
  // blackKnightOutpostSquares,
  // whiteDoubledPawns,
  // blackDoubledPawns
) => {
  // console.log('whiteIsolatedPawns', whiteIsolatedPawns);
  // console.log('whiteKnightOutpostSquares', whiteKnightOutpostSquares);

  let currentPositionPieceValues = [[], [], [], [], [], [], [], []];

  let mgPawn = 82;
  let mgKnight = 337;
  let mgBishop = 365;
  let mgRook = 477;
  let mgQueen = 1025;
  let mgKing = 0;

  let egPawn = 94;
  let egKnight = 281;
  let egBishop = 297;
  let egRook = 512;
  let egQueen = 936;
  let egKing = 0;

  let pieceMobility = 0;
  let bonus = 0;

  // console.log('this is run x times');

  // const unopposedBishopBonusCheck = (row, column, unopposedBishopSquare) => {
  const unopposedBishopBonusCheck = (row, column, gameDetails) => {
    let currentSquare;

    currentSquare = convertColumnsToLetters(column) + (row + 1);

    if (
      findSquareColour(currentSquare) ===
      gameDetails.unopposedBishopSquareColour
    ) {
      return 1;
    }
    return 0;
  };

  for (let column = 0; column <= 7; column++) {
    for (let row = 7; row >= 0; row--) {
      switch (gameDetails.currentPosition[row][column]) {
        case 'P':
          bonus = checkPawnValueBonus(
            convertColumnsToLetters(column) + (row + 1),
            gameDetails.whiteIsolatedPawns,
            gameDetails.blackIsolatedPawns,
            gameDetails.whiteDoubledPawns,
            gameDetails.blackDoubledPawns,
            'P'
          );

          currentPositionPieceValues[row][column] =
            Math.round(
              ((mgPawnTableWhite[row][column] + mgPawn) / 100) *
                10 *
                gamePhase +
                ((egPawnTableWhite[row][column] + egPawn) / 100) *
                  10 *
                  (1 - gamePhase) +
                bonus
            ) / 10;
          break;
        case 'p':
          bonus = checkPawnValueBonus(
            convertColumnsToLetters(column) + (row + 1),
            gameDetails.whiteIsolatedPawns,
            gameDetails.blackIsolatedPawns,
            gameDetails.whiteDoubledPawns,
            gameDetails.blackDoubledPawns,
            'p'
          );

          currentPositionPieceValues[row][column] =
            Math.round(
              ((mgPawnTableBlack[row][column] + mgPawn) / 100) *
                10 *
                gamePhase +
                ((egPawnTableBlack[row][column] + egPawn) / 100) *
                  10 *
                  (1 - gamePhase) +
                bonus
            ) / 10;
          break;
        case 'N':
          bonus = checkKnightOutpostBonus(
            convertColumnsToLetters(column) + (row + 1),
            gameDetails.whiteKnightOutpostSquares
          );

          currentPositionPieceValues[row][column] =
            Math.round(
              ((mgKnightTableWhite[row][column] + mgKnight) / 100) *
                10 *
                gamePhase +
                ((egKnightTableWhite[row][column] + egKnight) / 100) *
                  10 *
                  (1 - gamePhase) +
                bonus
            ) / 10;
          break;
        case 'n':
          bonus = checkKnightOutpostBonus(
            convertColumnsToLetters(column) + (row + 1),
            gameDetails.blackKnightOutpostSquares
          );

          currentPositionPieceValues[row][column] =
            Math.round(
              ((mgKnightTableBlack[row][column] + mgKnight) / 100) *
                10 *
                gamePhase +
                ((egKnightTableBlack[row][column] + egKnight) / 100) *
                  10 *
                  (1 - gamePhase) +
                bonus
            ) / 10;
          break;
        case 'B':
          // console.log(
          //   'unopposed bishop square:',
          //   unopposedBishopSquare,
          //   'row:',
          //   row,
          //   'column:',
          //   column
          // );

          // console.log(
          //   'unopposedBishopBonusCheck',
          //   unopposedBishopBonusCheck(row, column, unopposedBishopSquare)
          // );

          pieceMobility = checkPieceMobilityCount(
            convertColumnsToLetters(column) + (row + 1),
            chess,
            'B'
          );

          currentPositionPieceValues[row][column] =
            Math.round(
              (gameDetails.whiteAdvantageTags.includes('The Two Bishops')
                ? 2
                : 0) +
                ((mgBishopTableWhite[row][column] + mgBishop) / 100) *
                  10 *
                  gamePhase +
                ((egBishopTableWhite[row][column] + egBishop) / 100) *
                  10 *
                  (1 - gamePhase) +
                unopposedBishopBonusCheck(
                  row,
                  column,
                  gameDetails.unopposedBishopSquare
                ) +
                pieceMobility
            ) / 10;
          // console.log('moves', chess.moves());
          // console.log(
          //   'piece mobility ',
          //   convertColumnsToLetters(column) + (row + 1),
          //   pieceMobility,
          //   ' currentPositionPieceValues[row][column]',
          //   currentPositionPieceValues[row][column]
          // );

          break;
        case 'b':
          pieceMobility = checkPieceMobilityCount(
            convertColumnsToLetters(column) + (row + 1),
            chess,
            'b'
          );

          currentPositionPieceValues[row][column] =
            Math.round(
              (gameDetails.blackAdvantageTags.includes('The Two Bishops')
                ? 2
                : 0) +
                ((mgBishopTableBlack[row][column] + mgBishop) / 100) *
                  10 *
                  gamePhase +
                ((egBishopTableBlack[row][column] + egBishop) / 100) *
                  10 *
                  (1 - gamePhase) +
                unopposedBishopBonusCheck(
                  row,
                  column,
                  gameDetails.unopposedBishopSquare
                ) +
                pieceMobility
            ) / 10;

          break;
        case 'R':
          currentPositionPieceValues[row][column] =
            Math.round(
              ((mgRookTableWhite[row][column] + mgRook) / 100) *
                10 *
                gamePhase +
                ((egRookTableWhite[row][column] + egRook) / 100) *
                  10 *
                  (1 - gamePhase)
            ) / 10;
          break;
        case 'r':
          currentPositionPieceValues[row][column] =
            Math.round(
              ((mgRookTableBlack[row][column] + mgRook) / 100) *
                10 *
                gamePhase +
                ((egRookTableBlack[row][column] + egRook) / 100) *
                  10 *
                  (1 - gamePhase)
            ) / 10;
          break;
        case 'Q':
          currentPositionPieceValues[row][column] =
            Math.round(
              ((mgQueenTableWhite[row][column] + mgQueen) / 100) *
                10 *
                gamePhase +
                ((egQueenTableWhite[row][column] + egQueen) / 100) *
                  10 *
                  (1 - gamePhase)
            ) / 10;
          break;
        case 'q':
          currentPositionPieceValues[row][column] =
            Math.round(
              ((mgQueenTableBlack[row][column] + mgQueen) / 100) *
                10 *
                gamePhase +
                ((egQueenTableBlack[row][column] + egQueen) / 100) *
                  10 *
                  (1 - gamePhase)
            ) / 10;
          break;
        case 'K':
          currentPositionPieceValues[row][column] =
            Math.round(
              ((mgKingTableWhite[row][column] + mgKing) / 100) *
                10 *
                gamePhase +
                ((egKingTableWhite[row][column] + egKing) / 100) *
                  10 *
                  (1 - gamePhase)
            ) / 10;
          break;
        case 'k':
          currentPositionPieceValues[row][column] =
            Math.round(
              ((mgKingTableBlack[row][column] + mgKing) / 100) *
                10 *
                gamePhase +
                ((egKingTableBlack[row][column] + egKing) / 100) *
                  10 *
                  (1 - gamePhase)
            ) / 10;
          break;
        default:
          currentPositionPieceValues[row][column] = 0;
      }
      // if currentPosition[row][column] = pgnExpanded.charAt(column + (7 - row) * 8);
    }
  }
  // return currentPositionPieceValues;
  gameDetails.currentPositionPieceValues = currentPositionPieceValues;

  return gameDetails;
};

export const buildPieceValuesHTML = currentPositionPieceValues => {
  let htmlString = `<g class="piecevalues" >`;
  // let htmlString = `<g class="piecevalues" >`;
  // let htmlString = `<g class="coordinates">`;

  const pieceValueOffset = currentPositionPieceValue => {
    if (currentPositionPieceValue > 10 && currentPositionPieceValue < 100) {
      return 27;
    } else if (currentPositionPieceValue > 100) {
      return 39;
    } else {
      return 20;
    }
  };

  for (let column = 0; column <= 7; column++) {
    for (let row = 7; row >= 0; row--) {
      //class= "piecevalues"
      htmlString =
        htmlString +
        `<text  x="${
          73.8 * (row + 1) -
          pieceValueOffset(currentPositionPieceValues[7 - column][row])
        }" y="${
          73.8 * (column + 1) - 61
        }" style="font-size: 12px; color: rgb(255, 115, 222);">${
          currentPositionPieceValues[7 - column][row]
            ? currentPositionPieceValues[7 - column][row]
            : ''
        }</text>`;
    }
    //class="coordinate file light"
    //currentPositionPieceValues[7 - column][row]
  }
  htmlString = htmlString + '</g';
  return htmlString;
};

export const gamePhaseEvaluation = currentPosition => {
  let gamePhase = 0;

  for (let column = 0; column <= 7; column++) {
    for (let row = 7; row >= 0; row--) {
      switch (currentPosition[row][column]) {
        case 'P':
          gamePhase = gamePhase + 0;
          // gamePhase = gamePhase + currentPositionPieceValues[row][column] * 0;
          break;
        case 'p':
          gamePhase = gamePhase + 0;
          break;
        case 'N':
          gamePhase = gamePhase + 1;
          break;
        case 'n':
          gamePhase = gamePhase + 1;
          break;
        case 'B':
          gamePhase = gamePhase + 1;
          break;
        case 'b':
          gamePhase = gamePhase + 1;
          break;
        case 'R':
          gamePhase = gamePhase + 2;
          break;
        case 'r':
          gamePhase = gamePhase + 2;
          break;
        case 'Q':
          gamePhase = gamePhase + 4;
          break;
        case 'q':
          gamePhase = gamePhase + 4;
          break;
        case 'K':
          gamePhase = gamePhase + 0;
          break;
        case 'k':
          gamePhase = gamePhase + 0;
          break;
        default:
        // currentPositionPieceValues[row][column] = 0;
      }
      // if currentPosition[row][column] = pgnExpanded.charAt(column + (7 - row) * 8);
    }
  }

  if (gamePhase > 0) {
    // alert(gamePhase);
    // console.log('gamephase:', gamePhase);
    return gamePhase / 24;
  } else if (gamePhase === 0) {
    return 0;
  }
};
