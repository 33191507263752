import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Footer from './ui/Footer';
export const Help = () => {
  // const classes = useStyles();
  return (
    // <ThemeProvider theme={theme}>
    <div style={{ display: 'flex', alignItems: 'baseline' }}>
      <Grid
        container
        //Main page container
        justify="center"
        // style={{ backgroundColor: 'white' }}
        // style={{ padding: '0px' }}
      >
        <Paper
          // className={classes.paper}
          // style={{ maxHeight: 600, overflow: 'auto' }}
          // style={{ padding: '25px' }}
          elevation={0}
        >
          <Box mt={5} mb={5} width={1200}>
            <Grid
              container
              justify="center"
              spacing={2}
              style={{ padding: '25px' }}
            >
              <Grid item xs={7}>
                <Typography variant="h3" style={{ fontWeight: 600 }}>
                  Help
                </Typography>

                {/* <Typography variant="title" color="inherit" noWrap>
                  {' '}
                </Typography> */}

                <Typography variant="body1" style={{ marginTop: '2em' }}>
                  Improving your chess playing strength is not easy. I feel your
                  pain. We all know chess is complex. There are so many aspects
                  to the game. You have tactics, strategy,
                  openings/middlegame/endgame, time management, thinking
                  processes and much much more.
                </Typography>
                <Typography variant="body1" style={{ marginTop: '2em' }}>
                  Improving your chess playing strength is not easy. I feel your
                  pain. We all know chess is complex. There are so many aspects
                  to the game. You have tactics, strategy,
                  openings/middlegame/endgame, time management, thinking
                  processes and much much more.
                </Typography>
                {/* <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ marginTop: '1em' }}
                  // style={{ maxWidth: '108px', minWidth: '108px' }}
                  onClick={() => {
                    // handleClickImportPGN();
                  }}
                >
                  Import Games
                </Button> */}
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Footer />
      </Grid>
    </div>
    // </ThemeProvider>
  );
};

export default Help;
