// import React from 'react';
import { mgPawnTableWhite } from './pstArrays';
import { columnToNumber } from './utilities';

// Piece Value Calculations

export const pawnValue = coords => {
  let pawnValue = 100;
  let column;
  let row;

  column = columnToNumber(coords.charAt(0));
  row = parseInt(coords.charAt(1)) - 1;

  pawnValue = pawnValue + mgPawnTableWhite[column][row];

  // alert(pawnValue);
};
